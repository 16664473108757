var Tivoli = Tivoli || {};

Tivoli.globalStates = (function () {
  //
	// var state = {
	// 	menuOpen: false,
	// 	asideOpen: false,
	// 	topWidgetOpen: false
	// };
  //
	// var contentIsLocked = false;
  //
	// var lastScrolled;
	// var $html = $('html');
	// var $body = $('body');
	// var slideOutRightClassName = 'slideout-right-is-active',
	// 	menuIsActiveClassName = 'menu-is-active',
	// 	topWidgetClassName = 'top-widget-active';
	// 	// contextNavActiveClass = 'context-nav-is-active';
  //
	// function statesCurrentlyOpen(state) {
	// 	var statesOpen = [];
	// 	for (var item in state) {
	// 		if(state[item]) {
	// 			statesOpen.push(item);
	// 		}
	// 	}
	// 	return statesOpen;
	// }
  //
	// function lockScroll() {
	// 	if (!contentIsLocked && statesCurrentlyOpen(state).length < 1) {
	// 		var scrollTop = $(window).scrollTop();
	// 		lastScrolled = scrollTop;
	// 		$html.css({
	// 			top: -scrollTop
	// 		});
	// 		contentIsLocked = true;
	// 	}
	// }
  //
	// function setScroll(value) {
	// 	if (contentIsLocked && statesCurrentlyOpen(state).length < 1) {
	// 		$body.scrollTop(value);
	// 		contentIsLocked = false;
	// 	}
	// }
  //
	// var setState = {
	// 	menu: {
	// 		open: function () {
	// 			lockScroll();
	// 			$html.addClass(menuIsActiveClassName);
	// 			state.menuOpen = true;
	// 			triggerStateChange(state);
	// 		},
	// 		close: function () {
	// 			$html.removeClass(menuIsActiveClassName);
	// 			state.menuOpen = false;
	// 			triggerStateChange(state);
	// 			setScroll(lastScrolled);
	// 		}
	// 	},
	// 	aside: {
	// 		open: function () {
	// 			lockScroll();
	// 			$html.addClass(slideOutRightClassName);
	// 			state.asideOpen = true;
	// 			triggerStateChange(state);
	// 		},
	// 		close: function () {
	// 			$html.removeClass(slideOutRightClassName);
	// 			state.asideOpen = false;
	// 			triggerStateChange(state);
	// 			setScroll(lastScrolled);
	// 		}
	// 	},
	// 	topWidget: {
	// 		open: function () {
	// 			lockScroll();
	// 			$html.addClass(topWidgetClassName);
	// 			state.topWidgetOpen = true;
	// 			triggerStateChange(state);
	// 		},
	// 		close: function () {
	// 			$html.removeClass(topWidgetClassName);
	// 			state.topWidgetOpen = false;
	// 			triggerStateChange(state);
	// 			setScroll(lastScrolled);
	// 		}
	// 	},
	// 	closeAll: function () {
	// 		if (state.menuOpen) this.menu.close();
	// 		if (state.asideOpen) this.aside.close();
	// 		if (state.topWidgetOpen) this.topWidget.close();
	// 	}
	// };
  //
	// function triggerStateChange(newStates) {
	// 	$(document).trigger("StateChange", newStates);
	// }

	// return {
	// 	state: state,
	// 	setState: setState
	// };

})();