var Tivoli = Tivoli || {};

Tivoli.contentSpinner = function () {

	var jsContentSpinner = $('.js-content-spinner');

	var activeClass = 'content-spinner__item--active';

	var $contentSpinnerToggle = $('.js-content-spinner-toggle');

	function init() {

		if(jsContentSpinner.length <= 0) {
			return;
		}

		$contentSpinnerToggle.on('click', function(e) {
			e.preventDefault();

			var $contentSpinnerSection = $(this).closest('.js-content-spinner');

			$contentSpinnerSection.find('.' + activeClass).removeClass(activeClass).siblings().addClass(activeClass);

		});
	}

	function initContentSpinner() {
		jsContentSpinner.each(function() {

		});
	}


	$(document).ready(function () {
		init();
	});
}();