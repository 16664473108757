Tivoli = Tivoli || {};

Tivoli.imageComparison = (function () {
    var imageComparisonTitle = $('.image-comparison-title'),
        imageComparisonManchet = $('.image-comparison-manchet'),
        imageComparisonAfter = $('.image-comparison img'),
        imageComparisonBefore= $('.image-comparison .resize img');


    function init() {
        if ($('.image-comparison').length === 0) {
            return;
        }

         $('.image-comparison').each(function(){
            var cur = $(this);
            var width = cur.width()+'px';
            cur.find('.resize img').css('width', width);
            drags(cur.find('.handle'), cur.find('.resize'), cur);
        });

        $('.image-comparison-thumbnail').on('click', function(e) {
            switchCompareImage($(this));
        });
    }

    $(window).resize(function(){
        $('.image-comparison').each(function(){
            var cur = $(this);
            var width = cur.width()+'px';
            cur.find('.resize img').css('width', width);
        });
    });

    function switchCompareImage(compareImage) {
        var title = compareImage.attr('data-title'),
            manchet = compareImage.attr('data-manchet'),
            beforeImg = compareImage.attr('data-img-before'),
            afterImg = compareImage.attr('data-img-after');

        imageComparisonTitle.text(title);
        imageComparisonManchet.text(manchet);
        
        imageComparisonAfter.attr('src', afterImg);
        imageComparisonBefore.attr('src', beforeImg);

        $('.image-comparison-thumbnail--selected').removeClass('image-comparison-thumbnail--selected');
        compareImage.addClass('image-comparison-thumbnail--selected');
    }

    function drags(dragElement, resizeElement, container) {
        dragElement.on('mousedown touchstart', function(e) {
            
            dragElement.addClass('draggable');
            resizeElement.addClass('resizable');
            
            var startX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;
            
            var dragWidth = dragElement.outerWidth(),
                posX = dragElement.offset().left + dragWidth - startX,
                containerOffset = container.offset().left,
                containerWidth = container.outerWidth();
        
            if(Tivoli.vars.isAnyTouchDevice) {
                minLeft = containerOffset + 26;
                maxLeft = containerOffset + containerWidth - dragWidth - 25;
            } else {
                 minLeft = containerOffset + 28;
                maxLeft = containerOffset + containerWidth - dragWidth - 30;
            }
            
            dragElement.parents().on("mousemove touchmove", function(e) {
                
            var moveX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;
            
            leftValue = moveX + posX - dragWidth;
            
            if ( leftValue < minLeft) {
                leftValue = minLeft;
            } else if (leftValue > maxLeft) {
                leftValue = maxLeft;
            }
            
            widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';
                    
            $('.draggable').css('left', widthValue).on('mouseup touchend touchcancel', function () {
                $(this).removeClass('draggable');
                resizeElement.removeClass('resizable');
            });
            $('.resizable').css('width', widthValue);
            }).on('mouseup touchend touchcancel', function(){
            dragElement.removeClass('draggable');
            resizeElement.removeClass('resizable');
            });
            e.preventDefault();
        }).on('mouseup touchend touchcancel', function(e){
            dragElement.removeClass('draggable');
            resizeElement.removeClass('resizable');
        });
    }

    $(document).ready(init);

    return {
    };
})();