Tivoli = Tivoli || {};

Tivoli.formBase = (function () {
    function handleLoader(formContainer) {
        var errorText = formContainer.data("error-text");
        var successText = formContainer.data("success-text");
        if (!errorText) {
            errorText = "";
        }
        if (!successText) {
            successText = "";
        }

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function (e, request) {
            Tivoli.ButtonMessage.removeMessage(button);
            Tivoli.CircleLoader.loadInitial(loader, null);
        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                if (successText) {
                    Tivoli.ButtonMessage.showMessageDefault(button, successText);
                }
                if (formContainer.hasClass("js-success-reload")) {
                    location.reload();
                    Tivoli.CircleLoader.loadFinish(loader);
                } else {
                    if (formContainer.hasClass("js-loader-no-reset")) {
                        Tivoli.CircleLoader.loadFinish(loader);
                    } else {
                        Tivoli.CircleLoader.loadFinish(loader, function () {
                            Tivoli.CircleLoader.reset(loader);
                        });
                    }
                }
            } else {
                var error = errorText;
                if (data.Error) {
                    error = data.Error;
                }
                Tivoli.CircleLoader.reset(loader);
                Tivoli.ButtonMessage.showErrorMessageDefault(button, error);
            }
        });

        formContainer.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);

            if (errorText) {
                Tivoli.ButtonMessage.showErrorMessageDefault(button, errorText);
            }
        });
    }

    function pureRedirect() {
        $('form.js-success-redirect-no-messages').each(function () {
            var formContainer = $(this);
            formContainer.on("form-success", function(e, data) {
                if (data.Success) {
                    if (data.RedirectUrl) {
                        window.location.href = data.RedirectUrl;
                    } else {
                        location.reload();
                    }
                }
            });
            
        });
    }

    function handleInputRegexWarnings() {
        $('.js-regex-warning').on('blur', function() {
            if($(this).valid()) {
                var re = new RegExp($(this).data('custom-warning'));
                var warningMessage = $(this).data('custom-warning-message');
                if(!re.test($(this).val().toLowerCase(), "g")) {
                    $(this).siblings('.js-regex-warning-label').remove();
                    $(this).after('<span class="js-regex-warning-label error error-text">' + warningMessage + '</span>');
                } else {
                    $(this).siblings('.js-regex-warning-label').remove();
                }
            } else {
                $(this).siblings('.js-regex-warning-label').remove();
            }
        });
    }

    function init() {
        $('form.js-use-loader').each(function() {
            handleLoader($(this));
        });
        pureRedirect();
        handleInputRegexWarnings();
    }

    $(document).ready(init);

    return {

    };
})();