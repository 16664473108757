function Service(options) {
    this.self = this;
    self.cnt = null;
    var defaultOptions = {
        'actions': {}
    };

    this.options = $.extend(defaultOptions, options);

    this.invoke = function (url, parameters, callback, sync) {
        $.ajax({
            data: JSON.stringify(parameters),
            type: 'POST',
            contentType: "application/json; charset=utf-8",
            url: url,
            success: function (data) {
                callback(data);
            },
            fail: function (data) {

            }
        });
    };
}
