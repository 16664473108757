var Tivoli = Tivoli || {};

Tivoli.ShareFacebook = (function () {

    function init() {
        var shareFacebookButton = $(".js-share-facebook");

        if (shareFacebookButton.length === 0) {
            return;
        }

        shareFacebookButton.on("click", function (e) {
            e.preventDefault();

            var subject = $(this).data("share-subject");
            var message = $(this).data("share-message");
            var link = $(this).data("share-link");
            var image = $(this).data("share-image");
            var fbAppId = $(this).data("share-id");

            if (typeof FB === 'undefined')
            {
                FB.init({
                    appId: fbAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v2.9'
                });
            }
            
            FB.ui({
                method: 'feed',
                link: link,
                name: subject,
                description: message,
                picture: image

            }, function (response) { });
            
        });
    }

    $(document).on("ready", function () {
        init();
    });
})();