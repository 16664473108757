Tivoli = Tivoli || {};

Tivoli.education = (function () {
    function init() {
        $.each($('.js-education-signup-form'), function () {
            handleEducationSignup($(this));
            handleNewsletterSignupGradeInterests();
        });

        $.each($('.js-tivoligarden-signup-form'), function () {
            handleTivoliGardenSignup($(this));
        });

        $.each($('.js-education-newsletter-signup-form'), function () {
            handleEducationNewsletterSignup($(this));
        });

        $(".js-confirmation-container").on("click", ".js-education-back", function (event) {
            event.preventDefault();
            $(".js-education-signup-form").show();

            var button = $(".js-education-signup-form").find(".js-custom-submit a");
            var loader = button.find(".js-circle-loader");
            Tivoli.CircleLoader.reset(loader);

            $(".js-confirmation-container").empty();
        });

        $(".js-confirmation-container").on("click", ".js-tivoligarden-back", function (event) {
            event.preventDefault();
            $(".js-tivoligarden-signup-form").show();

            var button = $(".js-tivoligarden-signup-form").find(".js-custom-submit a");
            var loader = button.find(".js-circle-loader");
            Tivoli.CircleLoader.reset(loader);

            $(".js-confirmation-container").empty();
        });

        $('.js-input-show-fields-button input').change(function () {
            var showHideId = $(this).closest('.js-input-show-fields-button').data("show-id");
            if ($(this).prop("checked")) {
                $("[data-showhide-id=" + showHideId + "]").show();
            } else {
                $("[data-showhide-id=" + showHideId + "]").hide();
            }
        });


    }

    function handleEducationConfirm(lastForm, view) {
        var button = $('.js-confirmation-container').find(".button");
        var loader = button.find(".js-circle-loader");

        $(".js-confirmation-container").on("click", ".button", function () {
            Tivoli.CircleLoader.loadInitial(loader, null);

            $.ajax({
                url: view,
                traditional: true,
                cache: false,
                data: JSON.stringify(lastForm),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                error: function (err) {
                    Tivoli.CircleLoader.reset(loader);
                    Tivoli.ButtonMessage.showMessage(button, err.ErrorMessage);
                },
                success: function (data) {
                    if (data.Success) {
                        window.location.href = data.RedirectUrl;
                    } else {
                        Tivoli.CircleLoader.reset(loader, null);
                        Tivoli.ButtonMessage.showErrorMessage(button, data.ErrorMessage);
                    }
                },
                type: "POST"
            });
        });
    }


    function handleEducationSignup(formContainer) {

        var outerContainer = formContainer.parents(".js-education-signup-form-container");
        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        var lastForm = null;

        var confirmationContainer = $(".js-confirmation-container");


        formContainer.on("form-request", function (e, form) {
            lastForm = form;
            var mainProductDatePicker = outerContainer.find(".js-product-date").siblings('.datepicker-simple-inner');
            if (mainProductDatePicker.length > 0) {
                var mainProductDate = mainProductDatePicker.datepicker("getDate");
                console.log(mainProductDate);
                if (mainProductDate) {
                    lastForm.Form.EducationDate = Tivoli.dateUtil.formatLocalDateShort(mainProductDate);
                }
            }
            formContainer.show();
            Tivoli.CircleLoader.loadInitial(loader, null);

        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                $.ajax({
                    url: "/Schools/Education/EducationSignupConfirmation",
                    traditional: true,
                    cache: false,
                    data: JSON.stringify(lastForm),
                    dataType: "html",
                    contentType: "application/json; charset=utf-8",
                    error: function (err) {
                        Tivoli.CircleLoader.reset(loader);
                    },
                    success: function (data) {
                        if (data) {
                            if ($('#educationsignupformTime').val() != "") {
                                Tivoli.CircleLoader.loadFinish(loader, null);
                                formContainer.hide();
                                confirmationContainer.empty();
                                confirmationContainer.html(data);
                                confirmationContainer.data("form", JSON.stringify(lastForm));
                                $(".js-confirmation-container").off("click", ".button");
                                handleEducationConfirm(lastForm, "/Schools/Education/EducationSignupConfirmed");

                                $("html, body").animate({ scrollTop: 0 }, "fast");
                            } else {
                                $('.js-education-time-error').show();
                                Tivoli.CircleLoader.reset(loader);
                                Tivoli.ButtonMessage.showErrorMessage(button, $('.js-education-time-error').html(), 3000);
                            }

                        } else {
                            Tivoli.CircleLoader.reset(loader);
                        }
                    },
                    type: "POST"
                });
            } else {
                Tivoli.CircleLoader.reset(loader);
            }
        });

        formContainer.on("form-error", function (err) {
            Tivoli.CircleLoader.reset(loader);
        });

    };

    function handleNewsletterSignupGradeInterests() {
        $('#educationsignupformEducationNewsletterTermsAndConditions').on('change', function(e) {
            if($(this).is(':checked')) {
                $('.js-school-newsletter-grades').show();
                $('.js-school-newsletter-grades').removeClass('js-ignore-validation')
            } else {
                $('.js-school-newsletter-grades').hide();
                $('.js-school-newsletter-grades').addClass('js-ignore-validation')
            }
        });
    }
    

    function handleTivoliGardenSignup(formContainer) {

        var outerContainer = formContainer.parents(".js-tivoligarden-signup-form-container");
        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        var lastForm = null;

        var confirmationContainer = $(".js-confirmation-container");

        formContainer.on("form-request", function (e, form) {
            lastForm = form;
            formContainer.show();
            Tivoli.CircleLoader.loadInitial(loader, null);
        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                $.ajax({
                    url: "/Schools/Education/TivoliGardenSignupConfirmation",
                    traditional: true,
                    data: JSON.stringify(lastForm),
                    dataType: "html",
                    contentType: "application/json; charset=utf-8",
                    error: function (err) {
                        Tivoli.CircleLoader.reset(loader);
                    },
                    success: function (data) {
                        if (data) {
                            Tivoli.CircleLoader.loadFinish(loader, null);
                            formContainer.hide();
                            confirmationContainer.empty();
                            confirmationContainer.html(data);
                            confirmationContainer.data("form", JSON.stringify(lastForm));
                            $(".js-confirmation-container").off("click", ".button");
                            handleEducationConfirm(lastForm, "/Schools/Education/TivoliGardenSignupConfirmed");

                            $("html, body").animate({ scrollTop: 0 }, "fast");

                        } else {
                            Tivoli.CircleLoader.reset(loader);
                        }
                    },
                    type: "POST"
                });
            } else {
                Tivoli.CircleLoader.reset(loader);
            }
        });

        formContainer.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
        });


    };

    function handleEducationDateSelected(times, originalItem) {
        $('.js-education-no-available-times').hide();
        $('.js-education-time-loader').hide();
        $('.js-education-time').show();
        var $select = $('#educationsignupformTime');

        $select.find('option').not(':first').remove();
        $.each(times, function (key, value) {
            $select.append($('<option>', { value: value.CrmId + "|" + value.StartTime })
                    .text(value.StartTime));
        });

        $select.selectric({
            arrowButtonMarkup: '<b class="selectric-button"></b>',
            disableOnMobile: true,
            onInit: false
        });
        $select.on("change", function () {
            setSelect(this);
            $(this).trigger('blur'); // Make sure JQUERY VALIDATE sees the updated value (and validates it)
            $('.js-education-time-error').hide();
        });

        if (times.length == 0) {
            $('.js-education-no-available-times').show();
        }

    }

    function setSelect(ele) {
        var select = $(ele);
        var selectricButton = select.closest('.selectricWrapper').find('.selectric');
        var selectedOption = select.find(':selected');
        var selectedImage = selectedOption.data('imagesrc') !== undefined ? selectedOption.data('imagesrc') : '';

        if (selectedImage !== '') {
            selectricButton.addClass('selectric-has-icon');
            selectricButton.attr('style', 'background-image: url("' + selectedImage + '");');
        } else {
            selectricButton.removeClass('selectric-has-icon');
            selectricButton.attr('style', '');
        }
    }

    function getEducationOpenTimes(date, originalItem) {
        $('.js-education-time').hide();
        $('.js-education-time-loader').show();

        $.ajax({
            url: "/Schools/Education/EducationSignupGetTimes",
            cache: false,
            traditional: true,
            data: { dateInput: date, item: originalItem },
            contentType: "application/json; charset=utf-8",
            error: function (err) {
            },
            success: function (dates) {
                handleEducationDateSelected(dates, originalItem);
            },
            type: "GET"
        });
    }

    function handleEducationNewsletterSignup(formContainer) {

        var outerContainer = formContainer.parents(".js-education-newsletter-signup-container");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function() {
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                window.location.href = data.RedirectUrl;
            } else {
                 Tivoli.CircleLoader.reset(loader);
                 if(!data.GradeGroupChecked) {
                     $('.js-multicheckbox-error').css('display', 'inline-block');
                 }
                
            }
        });
        
        formContainer.on("form-error", function () {
             Tivoli.CircleLoader.reset(loader);
        });
    };

    $(document).ready(init);

    return {
        getEducationTimes: getEducationOpenTimes
    };
})();