var Tivoli = Tivoli || {};

Tivoli.FilterSorting = function() {
    var allTabs,
        allFilters,
        contentContainer,
        allContentItems,
        noResultsView,
        $window = $(window);
    
	// GRID SIZING PATTERN
	var largeBoxPattern = [0, 3, 4, 7, 8, 11, 12, 15, 16, 19, 20, 23, 24, 27, 28, 31, 32, 35, 36, 39, 40, 43, 44, 47, 48, 51, 52, 55, 56];
	var smallBoxPattern = [1, 2, 5, 6, 9, 10, 13, 14, 17, 18, 21, 22, 25, 26, 29, 30, 33, 34, 37, 38, 41, 42, 45, 46, 49, 50, 53, 54, 57, 58, 61, 62];
	var clearBoxPattern = [2, 6, 10, 14, 19, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50, 54, 58, 62, 66, 70, 74, 78, 82, 86, 90];
	var pullBoxPattern = [3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43, 47, 51, 55, 59, 63, 67, 71, 75, 79, 83, 87];
    

	function init() {
        allTabs = $(".js-page-filter-tab-navigation .tab-filter");
        allFilters = $(".js-tab-page");
        if (allTabs.length === 0 && allFilters.length === 0) {
            return;
        }

        // Initializes the sliders
        $('.slider').range();

        // Set BOX-SIZES
        Tivoli.Filter.getFilterSelection();

        contentContainer = $(".box-sorting");

        insertQuotes(contentContainer);
        
        allContentItems = contentContainer.find(".box-sorting-container");
        noResultsView = contentContainer.siblings(".filter-no-results");

        // We can have tabs without filters and filters without tabs
        if (allTabs.length > 0) {
            allTabs.each(function () {
                var currentTab = $(this);
                var currentFilterSelection = allFilters.filter("." + currentTab.data("id"));
                var currentContentItems = allContentItems.filter("." + currentTab.data("id"));
				var currentTabGridSetting = currentTab.data("grid");
                var isActive = currentTab.hasClass("js-selected");
                var f = new filter(currentTab, currentFilterSelection, currentContentItems, isActive, currentTabGridSetting);
            });
        } else {
            allFilters.each(function () {
                var currentFilterSelection = $(this);
				var currentTabGridSetting = currentFilterSelection.data("grid");
                if (currentFilterSelection.hasClass("ajax-filter")) {
                    return;
                }
                
                var f = new filter(null, currentFilterSelection, allContentItems, true, currentTabGridSetting);
            });
        }
        openFilterMobile();
    }

    function filter(tab, filterSelection, elements, activeFilter, currentTabGridSetting) {
        var $tab = tab,
            $filterSection = filterSelection,
            $filters = filterSelection.find(".filter-controls"),
            $elements = elements,
	        filterGroup,
	        outputString = '',
            isActive = activeFilter,
			gridType = currentTabGridSetting;

        // The "init" method will run on document ready and cache any jQuery objects we will need.
        var initFilter = function () {
            if ($tab && $tab.length) {
                filterGroup = {
                    $buttons: $filters.find('.checkbox'),
                    $range: $filters.find('.range'),
                    ranges: [],
                    $dropdown: $filters.find('select'),
                    active: ''
                };

                bindHandlers();
            } else if ($filters.length) {
                filterGroup = {
                    $buttons: $filters.find('.checkbox'),
                    $range: $filters.find('.range'),
                    ranges: [],
                    $dropdown: $filters.find('select'),
                    active: ''
                };

                bindHandlers();
            }

            if (filterGroup && isActive) {
                parseFilters();
            }
        };

        // The "bindHandlers" method will listen for whenever a button is clicked.
        var bindHandlers = function () {
            if ($tab) {
                // Handle filter clicks
                $tab.on('click', function(e) {
                    e.preventDefault();

                    // If the button is active, remove the active class, else make active and deactivate others.
                    $tab.addClass('js-selected').siblings('.filter').removeClass('js-selected');

                    parseFilters();
                    Tivoli.Filter.saveFilterSelection($tab);
                });
            }

            // WATCH FOR CHANGES
            $filters.on('change', function (e) {
                var $currentFilter = $(e.target);
                synchronizeFilters($currentFilter);
                
                parseFilters();
                Tivoli.Filter.saveFilterSelection();

            });
            noResultsView.find("a").on("click", function() {
                Tivoli.Filter.clearFilters();
                parseFilters();
                Tivoli.Filter.saveFilterSelection();
            });
        };

        var synchronizeFilters = function (currentFilter) {
            var id = currentFilter.data("id");
            if (!id) {
                return;
            }
            var identicalFilters = allFilters.find("." + id);
            if (identicalFilters.hasClass("dropdown")) {
                identicalFilters.val(currentFilter.val()).selectric('refresh');
            } else if (identicalFilters.hasClass("slider")) {
                var dataSelectedMax = parseInt(currentFilter.attr('data-selectedmax'));
                var dataSelectedMin = parseInt(currentFilter.attr('data-selectedmin'));
                identicalFilters.val([dataSelectedMin, dataSelectedMax]);
            } else if (identicalFilters.hasClass("checkbox")) {
                identicalFilters.prop("checked", currentFilter.prop("checked"));
            }
        };

        // The parseFilters method checks which filters are active in each group:
        var parseFilters = function () {
            // loop through each filter group and grap the active filter from each one.
            var dataSelectedMax,
                dataSelectedMin;
            
            filterGroup.active = "";
            filterGroup.ranges = [];

            filterGroup.$range.each(function () {
                var rangeFilter = $(this);

                dataSelectedMax = parseInt(rangeFilter.attr('data-selectedmax'));
                dataSelectedMin = parseInt(rangeFilter.attr('data-selectedmin'));

                dataSelectedMax = dataSelectedMax >= 0 ? dataSelectedMax : 1000;
                dataSelectedMin = dataSelectedMin >= 0 ? dataSelectedMin : 0;

                filterGroup.ranges.push({
                    searchData: rangeFilter.attr('class').match(/data-val-(\w+)/g),
                    rangeActive: [dataSelectedMin, dataSelectedMax]
                });
            });

            filterGroup.$dropdown.each(function () {
                var val = $(this).val();
                if (val && val.length) {
                    filterGroup.active += "." + val;
                }
            });
            filterGroup.$buttons.each(function () {
                var check = $(this);
                if (check.prop("checked")) {
                    filterGroup.active += "." + check.data("filter");
                }
            });

            applyFilters();
        };

        // The "concatenate" method will crawl through each group, concatenating filters as desired:
        var applyFilters = function () {
            var boxes = $elements;

            outputString = filterGroup.active; // Reset output string

            allContentItems.removeClass('visible');
            contentContainer.find('.quote').removeClass('visible');
            
            boxes = filters.rangeFilter(boxes);
            boxes = filters.selectedFilter(boxes);

            boxes.addClass('visible');
            
            insertQuotes(contentContainer);
            animateIn(contentContainer);
        };

        var filters = {
            rangeFilter: function (boxes) {
                for (var i = 0; i < filterGroup.ranges.length; i++) {
                    boxes = boxes.filter(function () {
                        var range = filterGroup.ranges[i];
                        var val = $(this).attr(range.searchData[0]);
                        var min = range.rangeActive[0];
                        var max = range.rangeActive[1];

                        return ((val >= min) && (val <= max));
                    });
                }
                return boxes;
            },
            selectedFilter: function (boxes) {
                //var checkboxFilters = $(outputString);
                if (outputString.length > 0 && boxes != null) {
                    return boxes.filter(outputString);
                } else {
                    return boxes;
                }
            }
        };

        function animateIn(container) {
            var quotes = container.find('.quote');
            var actives = container.find('.visible');

            var noResults = actives.length === 0 && $elements.length !== 0;

            TweenMax.to(container, 0.3, { autoAlpha: 0, onComplete: hideAllItems });

            function hideAllItems() {
                setBoxSizes(actives, container, gridType);
                quotes.not('.visible').remove();
                TweenMax.set(container, { autoAlpha: 1 });
                TweenMax.set(allContentItems, { autoAlpha: 0, display: 'none', onComplete: animateThemIn });

                if (!noResults && !noResultsView.hasClass("hidden")) {
                    noResultsView.addClass("hidden");
                }
                function animateThemIn() {
                    if (noResults && noResultsView.hasClass("hidden")) {
                        noResultsView.removeClass("hidden");
                    }
                    TweenMax.set(actives, { display: 'block' });
                    //TweenMax.staggerTo(actives, 0.2, { autoAlpha: 1 }, 0.1); // Animate items in
                    TweenMax.to(actives, 0.2, { autoAlpha: 1 }); // Animate items in
                    $window.trigger("lazyload");
                }
            }
        }

       // animateIn(container, gridType);
        initFilter();
    };

    $.fn.range = function () {

        return this.each(function () {
            var maxRange = $(this).attr('data-maxval') !== 'undefined' ? parseInt($(this).attr('data-maxval')) : 0;
            var minRange = $(this).attr('data-minval') !== 'undefined' ? parseInt($(this).attr('data-minval')) : 0;
            var step = $(this).attr('data-step') !== 'undefined' ? parseInt($(this).attr('data-step')) : 1;

            var selectedMax = $(this).attr('data-selectedmax') !== 'undefined' ? parseInt($(this).attr('data-selectedmax')) : maxRange;
            var selectedMin = $(this).attr('data-selectedmin') !== 'undefined' ? parseInt($(this).attr('data-selectedmin')) : minRange;
            
            $(this).noUiSlider({
                start: [0, 1000],
                step: step,
                margin: 1,
                connect: true,
                behaviour: 'tap-drag',
                format: wNumb({
                    decimals: 0
                }),
                range: {
                    'min': minRange,
                    'max': maxRange
                }
            });

            $(this).on({
                change: function () {
                    selectedMin = $(this).val()[0];
                    selectedMax = $(this).val()[1];
                    setRangeSearch($(this));
                }
            });

            function setRangeSearch(self) {
                self.attr('data-selectedmax', selectedMax);
                self.attr('data-selectedmin', selectedMin);
            }

            if ($(this).siblings('.sort-spec .min-val')) {
                $(this).Link('upper').to($(this).siblings('.sort-title').find('.min-val'));
            }
            if ($(this).siblings('.sort-spec .max-val')) {
                $(this).Link('lower').to($(this).siblings('.sort-title').find('.max-val'));
            }
            
            setRangeSearch($(this));
        });
    };

    function insertQuotes(elem) {

        var quote = $('.quotes .quote').clone();

        var boxSortingContainer = elem.find('.visible.box-sorting-container');
        var quotePosition = [2, 5, 10, 14, 18, 22, 26, 30, 34, 38];
        var quoteArray = [];

        if (quote.length > 0) {

            for (var i = 0; quote.length > i; i++) {
                $(quote[i]).addClass("visible");
                quoteArray.push(i);
            }

            // randomize order of elements with a sort function that randomly returns -1/0/1
            quoteArray.sort(function () {
                return Math.floor(Math.random() * 3) - 1;
            });

            function getNextRandom() {
                return quoteArray.shift();
            };

            var originalLength = quoteArray.length;

            for (var j = 0; originalLength > j; j++) {
                var randomQuote = quote[getNextRandom()];
                boxSortingContainer.eq(quotePosition[j]).before(randomQuote);
            }
        }
    }

    function setBoxSizes(sorter, container, gridType) {

        var allItems = container.find('.box-sorting-container');

        allItems.removeClass('large small clear pull-top');

        if (gridType === 'grid-sizing') {
            for (var i in largeBoxPattern) {
                var largeItems = largeBoxPattern[i];
                $(sorter).eq(largeItems).addClass('large');
            }
            for (var j in smallBoxPattern) {
                var smallItems = smallBoxPattern[j];
                $(sorter).eq(smallItems).addClass('small');
            }
            for (var k in clearBoxPattern) {
                var clearItems = clearBoxPattern[k];
                $(sorter).eq(clearItems).addClass('clear');
            }
            for (var l in pullBoxPattern) {
                var pullItems = pullBoxPattern[l];
                $(sorter).eq(pullItems).addClass('pull-top');
            }
        }
    }

    function openFilterMobile() {
        var $openFilter = $('.js-open-filter'),
			$hideFilter = $('.js-hide-filter');


        $openFilter.on('click', function (e) {
            e.preventDefault();
            //$('body').redraw();
            var filter = $(this).siblings('.filter-base');
            //hardwareAcceleration(false);
            overlayAttribute(true);
            TweenMax.fromTo(filter, 0.3, {top: '100%'}, {top: 0});
            filter.addClass('filter-open');
        });

        $hideFilter.on('click', function (e) {
            e.preventDefault();

            var filter = $(this).closest('.filter-base');
            TweenMax.fromTo(filter, 0.3, {top: 0}, {top: '100%', onComplete: function() {
                //hardwareAcceleration(true);
                overlayAttribute(false);
            }});
            filter.removeClass('filter-open');
        });

        $(document).on('menuOpen', function () {
            $('.filter-section').removeClass('filter-open');
        });
    }

    function overlayAttribute(set) {
        var $html = $('html');
        if(set === true) {
            $html.addClass('overlay-active');
        } else {
            $html.removeClass('overlay-active');
        }
    }

    function hardwareAcceleration(set) {
        var $html = $('html');
        if(set !== true) {
            $html.addClass('no-hardware-acceleration');
        } else {
            $html.removeClass('no-hardware-acceleration');
        }
    }

  /*  $.fn.redraw = function() {
        this.each(function() {
            $(this).wrap('<div></div>');

            setTimeout(function() {
                $(this).unwrap('');
            }, 0);
        });
    };*/


    $(document).ready(function () {
        init();
    });
}();