var Tivoli = Tivoli || {};
Tivoli.Pass = (function () {
    var containerLookup;
    var containerForm;
    var containerPopupForm;
    var renewPassChangedValues = false;

    function init() {
        initPassLookup();
        initPassForm();
        initPassPopupForm();
        initPassRenewForm();
        toogleMitTivoli();
        initPassActiveEmptyForm();
        initServicePermissionForm();
        
    }

    function initPassLookup() {
        containerLookup = $(".js-pass-validation");

        if (containerLookup.length === 0) {
            return;
        }

        var additionalVerificationContainer = $(".js-pass-additional-verification");
        if (additionalVerificationContainer.length > 0) {
            var additionalVerificationEnabledAtStartup = additionalVerificationContainer.data("enabled-at-startup");
            toggleAdditionalVerificationFirstName(additionalVerificationEnabledAtStartup);
        }

        handleLookupPost();
    }

    function toggleAdditionalVerificationFirstName(enable) {
        var additionalVerificationFirstName = $(".js-pass-additional-verification-first-name");
        if (additionalVerificationFirstName.length == 0) {
            return;
        }
        if (enable) {
            additionalVerificationFirstName.find(".js-validation-input").removeClass("ignore");
        } else {
            additionalVerificationFirstName.find(".js-validation-input").addClass("ignore");
        }
    }

    function initPassForm() {
        containerForm = $(".js-pass-form");

        if (containerForm.length === 0) {
            return;
        }

       
        handlePassRenewInputs();

        $.each(containerForm, function() {
             handlePassFormPost($(this));
             handlePassImage($(this)); 
        });
    }

     function initPassRenewForm() {
        containerForm = $(".js-pass-renew-form");

        if (containerForm.length === 0) {
            return;
        }

        // handlePassRenewInputs();
    }

    function initPassPopupForm() {
        containerPopupForm = $(".js-pass-popup-form");

        if (containerForm.length === 0) {
            return;
        }

        handlePassPopupFormPost();
    }

    function initFormWithQueryString() {
        var id = Tivoli.getUrlParameterByName("visualId");
        var message = Tivoli.getUrlParameterByName("message");
        if (id) {
            Tivoli.validation.getValidator(containerLookup).showErrors({ VisualId: message });
            containerLookup.find("#LookupPassVisualId").val(id);
        }
    }

    function initLoad() {
        if (containerLookup == null || containerLookup.length === 0) {
            return;
        }

        initFormWithQueryString();
    }
    
    function handleLookupPost() {
        var button = containerLookup.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        containerLookup.on("form-request", function (e, request) {
            Tivoli.CircleLoader.loadInitial(loader, null);
            if (!request.Form.VisualId) {
                request.Form.VisualId = $(".js-visualid-static").data("value");
            }

            $('.js-pass-error-message').hide();
        });

        containerLookup.on("form-success", function (e, data) {
            if (data.RedirectUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                window.location.href = data.RedirectUrl;
            } else {
                Tivoli.CircleLoader.reset(loader);
            }
        });
        containerLookup.on("form-error", function (e, data) {
            
            Tivoli.CircleLoader.reset(loader);
            if (data.FormErrors && data.FormErrors.FirstName) {
                // First is required - show it
                var additionalVerificationContainer = $(".js-pass-additional-verification");
                if (!additionalVerificationContainer.data("usedFirstTry") && !additionalVerificationContainer.data("enabled-at-startup")) {
                    // First time errors shows - show input first and remove error message
                    additionalVerificationContainer.data("usedFirstTry", true);
                    delete data.FormErrors.FirstName;
                    additionalVerificationContainer.show();
                    toggleAdditionalVerificationFirstName(true);
                }
            }else if(data.FormErrors && data.AlertMessage){
                var alert = data.AlertMessage
                Tivoli.ErrorPopup.show(alert.Title, alert.Body, alert.ButtonText,
                 function alertRedirect(){
                    window.location.href = alert.ButtonHref
                    return false;
                })
            }
        });
    }

    function handlePassImage(container) {
        var fileInput = container.find("input[type=file]");
        fileInput.on("file-data-changed", function (e, data) {
            container.find(".js-pass-image").get(0).src = data;
        });
    }
    
    function handlePassFormPost(container) {
        var button = container.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");
        var popUp = $(".pass-renew-permission-popup");
        var isMitTivoli = container.hasClass('js-is-mitTivoli');

        container.on('form-failed', function(e, eventInfo) { 
            openPassRenewFormFields();
        });
        
        

        
        var formError = container.find(".js-submit-form-error");
        formError.hide();

        container.on("form-request", function (e, request) {
            Tivoli.CircleLoader.loadInitial(loader, null);

            // Append visual id to form
            request["VisualId"] = Tivoli.getUrlParameterByNameInsensitive("visualid");
            
            // Append kind to form if any
            var kind = Tivoli.getUrlParameterByNameInsensitive("kind");
            if (kind) {
                request["Kind"] = kind;
            }

            request["IsMitTivoli"] = isMitTivoli;

            request["ValuesChanged"] = renewPassChangedValues;
        });

        container.on("form-success", function (e, data) {
            if (data.Success && data.RedirectUrl && data.ShowPermissionPopup && popUp.length >= 1) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                $(".delivery-information__inner").hide();
                $(".pass-renew-permission-popup").show();
                $("html, body").animate({ scrollTop: 0 }, "fast");
                $(".permission-no-thanks").on("click", function () {
                    e.preventDefault();
                    window.location.href = data.RedirectUrl;
                });
            }
            else if (data.Success && data.RedirectUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                window.location.href = data.RedirectUrl;
            } else {
                Tivoli.CircleLoader.reset(loader);
                
                if (data.Error) {
                    formError.empty();
                    formError.html(data.Error);
                    formError.show();
                    openPassRenewFormFields();
                    
                }
            }
        });
        
        container.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
            openPassRenewFormFields();
        });
    }
    function handlePassPopupFormPost() {
        var button = containerPopupForm.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");
        var popUp = $(".pass-renew-permission-popup");


        var formError = containerPopupForm.find(".js-submit-form-error");
        formError.hide();

        containerPopupForm.on("form-request", function (e, request) {
            Tivoli.CircleLoader.loadInitial(loader, null);

            // Append visual id to form
            request["VisualId"] = Tivoli.getUrlParameterByNameInsensitive("visualid");

            // Append kind to form if any
            var kind = Tivoli.getUrlParameterByNameInsensitive("kind");
            if (kind) {
                request["Kind"] = kind;
            }
        });

        containerPopupForm.on("form-success", function (e, data) {
            if (data.Success && data.RedirectUrl && data.ShowPermissionPopup && popUp.length >= 1) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                $(".delivery-information__inner").hide();
                $(".pass-renew-permission-popup").show();
                $("html, body").animate({ scrollTop: 0 }, "fast");
                $(".permission-no-thanks").on("click", function () {
                    e.preventDefault();
                    window.location.href = data.RedirectUrl;
                });
            }
            else if (data.Success && data.RedirectUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                window.location.href = data.RedirectUrl;
            } else {
                Tivoli.CircleLoader.reset(loader);
                if (data.Error) {
                    formError.empty();
                    formError.html(data.Error);
                    formError.show();
                }
            }
        });

        containerForm.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
        });
    }

    function handlePassRenewInputs() {
        $('.js-pass-form-edit').on('click', function(e) {
            e.preventDefault();
           openPassRenewFormFields();
        });
    }

    function toogleMitTivoli() {
        var mitTivoliPassword = $('.mit-tivoli-signup-password');
        $('.mit-tivoli-signup .custom-checkbox').on('change', function() {
            if(this.checked) {
                mitTivoliPassword.show();
            }
            else {
                mitTivoliPassword.hide();
            }
        });
    }

    function openPassRenewFormFields(){
        var passFormInfo = $('.js-pass-info');
        var passFormInput = $('.js-pass-hidden-input');
        var passFormAffectedCards = $('.js-pass-info-affected-cards');
        var passFormInfoLabel = $('.js-pass-form-info-label');

        passFormInfo.hide();
        passFormAffectedCards.show();
        passFormInput.show();
        renewPassChangedValues = true;
        passFormInfoLabel.hide();
    }

    function initPassActiveEmptyForm() {
        var passFormHidden = false;
        $('.js-activate-toogle-empty-form').on('click', function(e) {
            e.preventDefault();

            var passForm = $('.js-pass-form');
            var emptyPassForm =  $('.js-activate-empty-form');
            
            if(!passFormHidden) {
                passForm.hide();
                emptyPassForm.show();
                passFormHidden = true;
            }
            else {
                passForm.show();
                emptyPassForm.hide();
                passFormHidden = false;
            }
            
            $(window).scrollTop(0);
            
        });
    }

    function initServicePermissionForm() {
        var containerForm = $(".js-pass-service-permission-form");

        if (containerForm.length === 0) {
            return;
        }


        $.each(containerForm, function() {
             handleServicePermissionFormPost($(this));
        });
    }

    function handleServicePermissionFormPost(container) {
        var button = container.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        var formError = container.find(".js-submit-form-error");
        formError.hide();

        container.on("form-request", function (e, request) {
            Tivoli.CircleLoader.loadInitial(loader, null);

            // Append visual id to form
            request["VisualId"] = Tivoli.getUrlParameterByNameInsensitive("visualid");
        });

        container.on("form-success", function (e, data) {
            if (data.Success && data.RedirectUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                window.location.href = data.RedirectUrl;
            } else {
                Tivoli.CircleLoader.reset(loader);
            }
        });
        
        container.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
        });
    }


    $(document).ready(function () {
        init();
    });

    $(window).load(function () {
        initLoad();
    });

    return {

    };
})();