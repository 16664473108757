var Tivoli = Tivoli || {};


Tivoli.showFeedback = function() {
    "use strict";

    var smallBreak			=	Tivoli.settings.width.small,
		littleMediumBreak	=	Tivoli.settings.width.littlemedium,
		mediumBreak			=	Tivoli.settings.width.medium,
		largeBreak 			=	Tivoli.settings.width.large,
		xLargeBreak     = Tivoli.settings.width.xlarge,
		windowWidth,
		currentMQSize,
		allfeedBackSwipers = [];

    function init() {
        if($(".show-feedback__swiper").length === 0) {
		    return;
        }

		callFeedbackSwiper();
		
		$('.radio-section').on('click', function(e) {
			$(this).parents('.show-feedback__question--error').removeClass('.show-feedback__question--error');
		});

		 $.each($('.js-feedback-form'), function () {
			handleFeedbackPost($(this));
			handleFeedbackDropdownComment();
        });
		
    }

    function feedbackSwiperSettings(swiper) {
		var size = {
			 XLarge: function() {
		        swiper.params.slidesPerView = 3.2;
		        swiper.params.centeredSlides = true;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
			},
			Large: function() {
		        swiper.params.slidesPerView = 2.7;
		        swiper.params.centeredSlides = true;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
			Medium: function() {
		        swiper.params.slidesPerView = 1.7;
		        swiper.params.centeredSlides = true;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
		    Small: function() {
                swiper.params.slidesPerView = 1;
                swiper.params.centeredSlides = false;
                swiper.params.offsetSlidesBefore = 0;
                swiper.params.offsetSlidesAfter = 0;
		        reInitSwiper();
		    },
			reset: function() {
				var $swiper		=	$(swiper.container).find('.swiper'),
					$swipeBox	=	$swiper.find('.box-item');

				swiper.params.autoResize = false;
				swiper.params.slidesPerView = 'auto';
				swiper.params.slidesPerViewFit = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0;

				$swiper.removeAttr('style');
				$swipeBox.removeAttr('style');
				swiper.params.touchRatio = 0;
				
			},
			killTouch: function() {
				swiper.params.touchRatio = 0;
			}
		};

		function reInitSwiper() {
			var $swiper		=	$(swiper.container).find('.swiper'),
				$swipeBox	=	$swiper.find('.box-item');

			$swiper.removeClass('kill-swiper');
			$swiper.removeAttr('style');
			$swipeBox.removeAttr('style');
			swiper.reInit();
		}
		return size;
	}


    function callFeedbackSwiper(){
        MQWidth();
        currentMQSize = getMQSize();
        
        feedBackSwiperInit($('.show-feedback__swiper'));      

        $(window).smartresize(function() {
			MQWidth();
		});

		$(document).on('breakpoint', function(e) {
			$.each(allfeedBackSwipers, function() {
				setfeedBackSwiper(this, currentMQSize);
			});
        });
    }

    function getMQSize() {

		windowWidth = $(window).width();
		var newMQSize;

		if (xLargeBreak < windowWidth && windowWidth > largeBreak) {
			newMQSize = 'xxlarge';
		}else if(largeBreak < windowWidth && windowWidth > mediumBreak) {
			newMQSize = 'xlarge';
		} else if (largeBreak >= windowWidth && windowWidth > mediumBreak) {
			newMQSize = 'large';
		} else if((mediumBreak >= windowWidth && littleMediumBreak < windowWidth)) {
			newMQSize = 'medium';
		} else if (littleMediumBreak >= windowWidth && smallBreak < windowWidth) {
			newMQSize = 'littlemedium';
		} else if ((smallBreak >= windowWidth)) {
			newMQSize = 'small';
		}
		return newMQSize;
	}

	function MQWidth() {
		windowWidth = $(window).width();

		if (currentMQSize !== getMQSize()) {
			currentMQSize = getMQSize();
			$(document).trigger('breakpoint', [currentMQSize]);
		}
	}

    function feedBackSwiperInit(swiperContainer){
        //  var feedbackSwiper = new Swiper('.show-feedback__swiper', {
        //     speed: 400,
        //     slidesPerView: 3.1,
        //     centeredSlides: true
        // });

       
        
		var swiperSettings = {
			speed: 400,
			 slidesPerView: 3.1,
			 autoHeight: true,
             onSwiperCreated: setfeedBackSwiper,
             onSlideChangeStart: swiperPositionCallback
		};

		var feedbackSwiper = new Swiper(swiperContainer[0], swiperSettings);
		allfeedBackSwipers.push(feedbackSwiper);
    }

    function swiperPositionCallback(swiper) {
        var $swiperContainer = $(swiper.container);
        var swiperItemLength = $swiperContainer.find('.swiper-slide').length - 1;
        
        if(swiper.activeIndex == 0) {
            $('.js-show-feedback__button').show();
            $('.js-show-feedback__button__progress-start').show();
            $('.js-show-feedback__button__progress-index').hide();
			$('.js-show-feedback__button__progress-label').hide();
			$('.show_feedback__button-container .js-custom-submit').hide();
        } else if(swiper.activeIndex == swiperItemLength) {
			$('.js-show-feedback__button').hide();
			$('.show_feedback__button-container .js-custom-submit').show();
        } else {
            $('.js-show-feedback__button__progress-start').hide();
            $('.js-show-feedback__button').show();
            $('.js-show-feedback__button__progress-index').show();
            $('.js-show-feedback__button__progress-index').html(swiper.activeIndex + ' / ' + swiperItemLength);
			$('.js-show-feedback__button__progress-label').show();
			$('.show_feedback__button-container .js-custom-submit').hide();
        }
    }

    function setfeedBackSwiper(swiper, MQWidth) {
		MQWidth = MQWidth !== undefined ? MQWidth : currentMQSize;

		var $swiperContainer = $(swiper.container);
		var swiperItemLength = $swiperContainer.find('.box-item').length;


		if (MQWidth === 'xxlarge') {
            feedbackSwiperSettings(swiper).XLarge();
			feedbackControl(swiper, true);
		} else if (MQWidth === 'xlarge') {
            feedbackSwiperSettings(swiper).XLarge();
			feedbackControl(swiper, true);

		} else if (MQWidth === 'large') {
            feedbackSwiperSettings(swiper).Large();
			feedbackControl(swiper, true);

		} else if (MQWidth === 'littlemedium' || MQWidth === 'medium' ) {
			feedbackSwiperSettings(swiper).Medium();
			feedbackControl(swiper, true);
		} else if (MQWidth === 'small') {
            feedbackSwiperSettings(swiper).Small();            
			feedbackControl(swiper, false);
		}
    }

    function feedbackControl(swiper, setButtonWidth) {
        var boxItemWidth = $(".show-feedback__swiper").find('.show-feedback__question').width();
        
        if(setButtonWidth) {
			$('.js-show-feedback__button').width(boxItemWidth + 10);
			$('.show_feedback__button-container .js-custom-submit a').width(boxItemWidth + 10);
        } else {
			$('.js-show-feedback__button').css('width', 'calc(100% - 10px)');
			$('.show_feedback__button-container .js-custom-submit a').css('width', 'calc(100% - 10px)');
        }

        $('.js-show-feedback__button').on('click', function(e) {
			e.preventDefault();
            swiper.swipeNext();
		});
		
		handleErrors(swiper);
	}
	
	function handleErrors(swiper) {
		$('.js-custom-submit').on('click', function() {
			var questionIndex = 0;
			var errorIndex = 0;
			$.each($('.show-feedback__question'), function() {
				var error = $(this).find('.error-text');
				if(error.length != 0 && error.css('display') != 'none') {
					$(this).addClass('show-feedback__question--error');
					if(errorIndex == 0) {
						errorIndex = questionIndex;
					}
				} else {
					$(this).removeClass('show-feedback__question--error');
				}
				questionIndex++;
			});
			if(errorIndex != 0) {
				swiper.swipeTo(errorIndex);
			}
		});

		$('.js-validation-input').change(function(){
			$(this).parents('.show-feedback__question--error').removeClass('show-feedback__question--error');
		});
	}

	function handleFeedbackDropdownComment() {
		$.each($('.js-feedback-comment .js-validation-input'), function() {
			var $commentInput = $(this).parents('.js-feedback-comment');
			var numberOfOptions = $(this).children('option').length - 1;
			$(this).on("change", function() {
				if($(this).find("option:selected").index() == numberOfOptions) {
					$commentInput.addClass('js-feedback-comment--enabled');
				} else {
					$commentInput.removeClass('js-feedback-comment--enabled');
				}
			});
		});
	}

	function handleFeedbackPost(formContainer) {

        var outerContainer = formContainer.parents(".show-feedback");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function() {
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
				outerContainer.hide();
				$('.show-feedback-promotions').show();
            } else {
				 Tivoli.CircleLoader.reset(loader);
				 Tivoli.ButtonMessage.showErrorMessage(button, data.ErrorMessage);
            }
        });
        
        formContainer.on("form-error", function () {
             Tivoli.CircleLoader.reset(loader);
        });
	};
	
	
    
    

    
     $(document).ready(function () {
	    init();
	});

}();

