var Tivoli = Tivoli || {};


/***********************************
Author: Lars Sørensen - Tivoli
***********************************/
Tivoli.sharePage = (function () {

    function init() {
        var sharePageButton = $(".js-share-page");

        if (sharePageButton.length === 0) {
            return;
        }

        sharePageButton.each(function () {

            var sharePage = $(this);

            sharePage.on("click", function () {
                var shareId = $(this).data("share-id");
                var containerToExpand = $("[data-shared-id=" + shareId + "]");
                var openHeight = containerToExpand.children(".text-fade-container").height();

                if (!containerToExpand.is(":visible")) {
                    containerToExpand.show("medium");
                } else {
                    containerToExpand.hide("medium");
                }

            });
        });

        $("#share-link").on("click", function () {
            $(this).select();
        });

    }

    $(document).on("ready", function () {
        init();
    });

}());
