var Tivoli = Tivoli || {};


/***********************************
countUp.js
Author: @inorganik
***********************************/
// target = id of html element or var of previously selected html element where counting occurs
// startVal = the value you want to begin at
// endVal = the value you want to arrive at
// decimals = number of decimal places, default 0
// duration = duration of animation in seconds, default 2
// options = optional object of options (see below)


// Example:
// var numAnim = new countUp("SomeElementYouWantToAnimate", 0, 99.99, 2, 2.5);
// numAnim.start();
// with optional callback:
// numAnim.start(someMethodToCallOnComplete);

Tivoli.countUp = function (target, startVal, endVal, decimals, duration, options) {

	// make sure requestAnimationFrame and cancelAnimationFrame are defined
	// polyfill for browsers without native support
	// by Opera engineer Erik M�ller
	var lastTime = 0;
	var vendors = ['webkit', 'moz', 'ms', 'o'];
	for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
		window.cancelAnimationFrame =
		  window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
	}
	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = function (callback, element) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max(0, 16 - (currTime - lastTime));
			var id = window.setTimeout(function () { callback(currTime + timeToCall); },
			  timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		}
	}
	if (!window.cancelAnimationFrame) {
		window.cancelAnimationFrame = function (id) {
			clearTimeout(id);
		}
	}

	// default options
	this.options = options || {
		useEasing: true, // toggle easing
		useGrouping: true, // 1,000,000 vs 1000000
		separator: ',', // character to use as a separator
		decimal: '.', // character to use as a decimal
	}
	if (this.options.separator == '') this.options.useGrouping = false;
	if (this.options.prefix == null) this.options.prefix = '';
	if (this.options.suffix == null) this.options.suffix = '';

	var self = this;

	this.d = (typeof target === 'string') ? document.getElementById(target) : target;
	this.startVal = Number(startVal);
	this.endVal = Number(endVal);
	this.countDown = (this.startVal > this.endVal) ? true : false;
	this.startTime = null;
	this.timestamp = null;
	this.remaining = null;
	this.frameVal = this.startVal;
	this.rAF = null;
	this.decimals = Math.max(0, decimals || 0);
	this.dec = Math.pow(10, this.decimals);
	this.duration = duration * 1000 || 2000;

	this.version = function () { return '1.3.2' }

	// Print value to target
	this.printValue = function (value) {
		var result = (!isNaN(value)) ? self.formatNumber(value) : '--';
		if (self.d.tagName == 'INPUT') {
			this.d.value = result;
		}
		else if (self.d.tagName == 'text') {
			this.d.textContent = result;
		}
		else {
			this.d.innerHTML = result;
		}
	}

	// Robert Penner's easeOutExpo
	this.easeOutExpo = function (t, b, c, d) {
		return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
	}
	this.count = function (timestamp) {

		if (self.startTime === null) self.startTime = timestamp;

		self.timestamp = timestamp;

		var progress = timestamp - self.startTime;
		self.remaining = self.duration - progress;

		// to ease or not to ease
		if (self.options.useEasing) {
			if (self.countDown) {
				var i = self.easeOutExpo(progress, 0, self.startVal - self.endVal, self.duration);
				self.frameVal = self.startVal - i;
			} else {
				self.frameVal = self.easeOutExpo(progress, self.startVal, self.endVal - self.startVal, self.duration);
			}
		} else {
			if (self.countDown) {
				var i = (self.startVal - self.endVal) * (progress / self.duration);
				self.frameVal = self.startVal - i;
			} else {
				self.frameVal = self.startVal + (self.endVal - self.startVal) * (progress / self.duration);
			}
		}

		// don't go past endVal since progress can exceed duration in the last frame
		if (self.countDown) {
			self.frameVal = (self.frameVal < self.endVal) ? self.endVal : self.frameVal;
		} else {
			self.frameVal = (self.frameVal > self.endVal) ? self.endVal : self.frameVal;
		}

		// decimal
		self.frameVal = Math.round(self.frameVal * self.dec) / self.dec;

		// format and print value
		self.printValue(self.frameVal);

		// whether to continue
		if (progress < self.duration) {
			self.rAF = requestAnimationFrame(self.count);
		} else {
			if (self.callback != null) self.callback();
		}
	}
	this.start = function (callback) {
		self.callback = callback;
		// make sure values are valid
		if (!isNaN(self.endVal) && !isNaN(self.startVal)) {
			self.rAF = requestAnimationFrame(self.count);
		} else {
			console.log('countUp error: startVal or endVal is not a number');
			self.printValue();
		}
		return false;
	}
	this.stop = function () {
		cancelAnimationFrame(self.rAF);
	}
	this.reset = function () {
		self.startTime = null;
		self.startVal = startVal;
		cancelAnimationFrame(self.rAF);
		self.printValue(self.startVal);
	}
	this.resume = function () {
		self.stop();
		self.startTime = null;
		self.duration = self.remaining;
		self.startVal = self.frameVal;
		requestAnimationFrame(self.count);
	}
	this.formatNumber = function (nStr) {
		nStr = nStr.toFixed(self.decimals);
		nStr += '';
		var x, x1, x2, rgx;
		x = nStr.split('.');
		x1 = x[0];
		x2 = x.length > 1 ? self.options.decimal + x[1] : '';
		rgx = /(\d+)(\d{3})/;
		if (self.options.useGrouping) {
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + self.options.separator + '$2');
			}
		}
		return self.options.prefix + x1 + x2 + self.options.suffix;
	}

	// format startVal on initialization
	self.printValue(self.startVal);

};


/***********************************
Author: Lars Linde
***********************************/
Tivoli.annualreport = (function () {

	function plusButton() {
		$("button.button-plus").on("click", function () {
			$(this).toggleClass("transform");
			var expanderId = $(this).data("expander-id");
			var containerToExpand = $("[data-expand-id=" + expanderId + "]");
			containerToExpand.toggleClass("is-expanded");
		});
	}


	$(document).on("ready", function () {
	    var entrance = $("#tivoli-entrance");

		//countUp options
		var options = {
			useEasing: false,
			useGrouping: true,
			separator: '.',
			decimal: '.',
			prefix: '',
			suffix: ''
		}

		function countUpCallBack() {
			//console.log("countUp complete");
		    //Har kunne vi s� animere SVG filen ovenover
		    entrance.attr("src", entrance.attr("data-src"));
		}


		$('#visitors').appear();
		$('#visitors').on('appear', function (event, $allAppearedElements) {
			if ($(this).hasClass("complete"))
				return;

		    var start = $(this).data("start");
		    var end = $(this).data("end");
		    var numAnim = new Tivoli.countUp("visitors", start, end, 0, 3, options);
			numAnim.start(countUpCallBack);

			$(this).addClass("complete");

		});

		//$('#visitors').on('disappear', function (event, $allDisappearedElements) {
		//	console.log("GONE!");
		//});


		//The new SVG element
		$(".quarters-container").appear();
		$(".quarters-container").on("appear", function (event, $allAppearedElements) {
			if ($(this).hasClass("complete"))
				return;

			//$(this).addClass("complete");
			$(this).addClass("anim");
			//$(this).addClass("start-blob-anim");

			//var iOs = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			//var edge = navigator.appVersion.indexOf("Edge/") > -1;
			//if ($("html").hasClass("lt-ie11") || $("html").hasClass("ie-modern") || $("html").hasClass("safari") || iOs || edge) {
			//	//these browsers cause troble with the 'animation-fill-mode: farwards' property so don't run the line animation here
			//	return;
			//}



			//if (feature.css3Dtransform && feature.cssTransform && feature.cssTransition)
			//{
			//	var svg = $(this).find("#quarters");
			//	svg.attr("class", "start-anim"); //can't use addClass on SVG's
			//}
		});



		var visitorCalculator = function () {
			function getMaxOfArray(numArray) {
				return Math.max.apply(null, numArray);
			}
			var arr = new Array();
			var maxValue = 0;
			$(".season").each(function () {
				var val = $(this).data("value");
				arr.push(val);
			});
			maxValue = getMaxOfArray(arr);
			$(".season").each(function () {
				var val = $(this).data("value");
				var h = (val / maxValue) * 100;
				$(this).css("width", h + "%");
			});
		};

		plusButton();
		visitorCalculator();
	});

}());


Tivoli.turnover = (function() {

	var breakpoint = 980;
	var element = $(".visitors-by-season");
	var allSeasons = $(".season");

	function setLabels() {

		$(".season-label").removeClass("season-label--mobile");
		$(".season-label").removeAttr("style");
		$(".season.season--out-of-season .season-label").removeClass("season-label--absolute");

		if ($(window).width() > breakpoint) {
			$(".season.season--out-of-season .season-label").addClass("season-label--absolute");
			return;
		}

		allSeasons.each(function(index) {
			var label = $(this).find(".season-label"),
				w = $(this).width();
			
			if (w < 145) {
				label.addClass("season-label--mobile");
				label.css("left", w + 10 + "px");
			}
		});
	}

	$(window).smartresize(function () {
		setLabels();
	});

	$(window).on("load", function () {
		setLabels();
	});


}());


/***********************************
Author: Christian Normann Eiland
***********************************/
Tivoli.ages = (function () {
	var container;
	var ageSections;
	var ages = [];
	var breakpointMobile = 980;
	var breakpointSmallMobile = 460;
	var isMobile = false;
	var isSmallMobile = false;

	$(document).ready(function () {
	    container = $(".ageSections");
	    ageSections = $(".ageSection");
	    ageSections.each(function (index) {
	        var self = $(this);
	        ages.push(self.find(".age"));
	    });
	    setIsMobile();
	    setAges(true);
	});

	$(window).smartresize(function () {
		setIsMobile();
		setAges(false);
	});

	function setIsMobile() {
		isMobile = $(window).width() <= breakpointMobile;
		isSmallMobile = $(window).width() <= breakpointSmallMobile;
	}

	function setAges(setup) {

		var agesData = [];
		var data;
		var d;
		var needMobileMode = false;
		var largestText = 0;

		for (var i = 0; i < ages.length; i++) {
			var ags = ages[i];
			var outer = $(ageSections.get(i));
			var totalWidth = outer.width() - 1;

			var totalPadding = (ags.length - 1) * 4;
			var totalColWidth = totalWidth - totalPadding;

			data = [];
			agesData.push(data);

			ags.each(function (index) {
				d = {};

				var age = $(this);
				var col = age.find(".ageColumn");
				var value = col.data("value");
				var inner = age.find(".ageInner");
				var pointerTop = inner.find(".agePointerTop");
				var pointerMiddle = inner.find(".agePointerMiddle");
				var pointerBottom = inner.find(".agePointerBottom");
				var desc = age.find(".ageDesc");

				// Measure text
				var textMeasurer = outer.find(".ageTextMeasurer");
				textMeasurer.text(desc.text());
				var textW = textMeasurer.width() + 1;

				if (textW > largestText) {
					largestText = textW;
				}

				var w = value * totalColWidth / 100;

				if (setup) {
					var valueElem = col.find(".ageValue");
					valueElem.text(value + "%");
				}

				d.totalColWidth = totalColWidth;
				d.value = value;
				d.inner = inner;
				d.desc = desc;
				d.w = w;
				d.textW = textW;
				d.pTop = pointerTop;
				d.pMiddle = pointerMiddle;
				d.pBottom = pointerBottom;

				data.push(d);
			});

			var pullLeft = 0;

			for (var j = data.length - 1; j >= 0; j--) {
				d = data[j];

				var overflow = d.textW - d.w;

				var toMove = pullLeft + overflow / 2;
				if (toMove < 0) {
					toMove = 0;
				}
				d.toMove = toMove;

				var pointerMargin = 7;
				// Move pointer top
				var moveTop = 0;
				if (j < data.length - 1) {
					var last = data[j + 1];
					moveTop = Math.floor(last.toMove - last.w / 2 - d.w / 2 + pointerMargin);
					if (moveTop < 0) {
						moveTop = 0;
					}
				}

				d.descLeft = d.w / 2 - d.textW / 2 - toMove;
				d.pTopLeft = d.w / 2 - 1 - moveTop;
				d.pMiddleLeft = d.w / 2 - 1 - toMove;
				d.pBottomLeft = d.w / 2 - 1 - toMove;
				d.pBottomWidth = 2 + toMove - moveTop;

				pullLeft += overflow + 10;

				// Top moved out of container
				if (d.pTopLeft < 5) {
					needMobileMode = true;
				}
			}
		}

		if (needMobileMode) {
			container.addClass("mobile");
		} else {
			container.removeClass("mobile");
		}

		var cW = container.width();

		var largestValue = 0;
		if (isMobile) {
			// Find largest value
			for (var a = 0; a < agesData.length; a++) {
				data = agesData[a];
				for (var b = data.length - 1; b >= 0; b--) {
					d = data[b];
					if (d.value > largestValue) {
						largestValue = d.value;
					}
				}
			}
		}

		// Move objects
		for (var c = 0; c < agesData.length; c++) {
			data = agesData[c];

			for (var k = data.length - 1; k >= 0; k--) {
				d = data[k];

				if (needMobileMode) {
					var marginLeft = largestText + 15;
					d.inner.css("margin-left", marginLeft);
					d.desc.width(d.textW);
					d.inner.width((cW - marginLeft) * (d.value / largestValue));
				} else {
					d.inner.css("margin-left", "auto");
					d.inner.width(d.w);
					d.desc.css("left", d.descLeft);
					d.desc.width(d.textW);
					d.pTop.css("left", d.pTopLeft);
					d.pMiddle.css("left", d.pMiddleLeft);
					d.pBottom.css("left", d.pBottomLeft);
					d.pMiddle.width(d.pBottomWidth);
				}
			}
		}
	}

}());


/***********************************
Author: Christian Normann Eiland
***********************************/
Tivoli.segments = (function () {
	var columns;
	var segments;
	var numSegments;
	var breakpointMobile = 980;
	var breakpointSmallMobile = 460;
	var isMobile = false;
	var isSmallMobile = false;

	$(document).on("ready", function () {
		columns = $(".segmentColumn");
		segments = $(".segment");
		numSegments = segments.length;

		setIsMobile();
		setColumn();
		handleIconBox();
	});


	$(window).smartresize(function () {
		setIsMobile();
		setColumn();
	});

	function setIsMobile() {
		isMobile = $(window).width() <= breakpointMobile;
		isSmallMobile = $(window).width() <= breakpointSmallMobile;
	}

	function setColumn() {
		if (!columns) {
			return;
		}

		columns.each(function (index) {
			var self = $(this);
			var outer = self.parent(".segmentType");
			var value = self.data("value");

			var percentPixels = isSmallMobile ? 2.6 : 5;
			var showInnerLimit = isSmallMobile ? 12 : 5;

			if (value) {
				if (isMobile) {
					self.width(value * percentPixels);
					self.height("");
				} else {
					self.height(value * percentPixels);
					self.width("");
				}

				var showInner = value > showInnerLimit;
				var outerValue = outer.find(".outerValue");
				var innerValue = outer.find(".innerValue");
				outerValue.text(value + "%");
				innerValue.text(value + "%");
				if (showInner) {
					outerValue.hide();
					innerValue.show();
				} else {
					outerValue.show();
					innerValue.hide();
				}
			}
		});
	}

	function handleIconBox() {
		if (!segments) {
			return;
		}

		var isTouchDevice = $("html").hasClass("touch");

		segments.each(function (index) {
			var self = $(this);
			var info = self.find(".segmentInfo");
			var box = self.find(".segmentInfoBox");

			info.on(isTouchDevice ? "click" : "mouseenter", function (evt) {
				evt.stopPropagation();
				
				var top = -(box.height() / 2 - info.height() / 2);
				var left;

				if (isMobile) {
					left = 0;
				} else {
					left = -(box.width() / 2 - info.width() / 2);
					if (index == 0) {
						var minLeft = -(self.width() / 2 - info.width() / 2);
						left = Math.max(minLeft, left);
					}
					if (index == numSegments - 1) {
						var maxLeft = self.width() / 2 + info.width() / 2 - box.width();
						left = Math.min(maxLeft, left);
					}
				}

				$(".segmentInfoBox").hide(); //hide all boxes every time, before showing the one in focus

				box.css("left", left);
				box.css("top", top);
				box.show();
			}).on("mouseleave", function() {
				box.hide();
			});

		});


		$(document).on("click", function(e) {
			if (e.target.className !== "segmentInfo") {
				$(".segmentInfoBox").hide();
			}
		});

	}
}());

/************************
Author: Mikkel Nielsen
************************/
Tivoli.recommend = (function () {
	function drawCircle(elem) {
		var currentElem = $(elem),
            percent = currentElem.attr("percent"),
            counterElem = currentElem.find("#svgcounter"),
            i = 0,
            stepSize = 3.6,
            numberOfSteps = percent * 1, // Force int
            circle = currentElem.find(".arc")[0],
            angle = -90 - (4 * stepSize),
            radius = 135,
            outerRow = currentElem.parents(".row");

		var interval = setInterval(
            function () {
            	angle += stepSize;
            	angle %= 360;
            	var radians = (angle / 180) * Math.PI;
            	var x = 150 + Math.cos(radians) * radius;
            	var y = 150 + Math.sin(radians) * radius;
            	var e = circle.getAttribute("d");
            	var d;
            	if (i == 0) {
            		d = e + " M " + x + " " + y;
            	} else {
            		d = e + " L " + x + " " + y;
            	}
            	if (i >= (numberOfSteps + 8)) {
            		outerRow.css("zoom", 1); // IE fix
            		clearInterval(interval);
            	}
            	if (i <= numberOfSteps) {
            		counterElem.text(i);
            	}

            	if (i > 120)
            		clearInterval(interval);

            	circle.setAttribute("d", d);
            	i++;
            }
        , 20);
	}

	$('.svg-recommend').appear();
	$('.svg-recommend').on('appear', function (event) {
		if ($(this).parent().hasClass("complete"))
			return;

		$(this).parent().addClass("complete");

		drawCircle($(this));
	});
})();