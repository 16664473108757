Tivoli = Tivoli || {};

Tivoli.newsletter = (function() {
    function init() {
        $.each($('.js-newsletter-signup-form'), function() {
            handleNewsletterSignup($(this));
        });
        $.each($('.js-newsletter-edit-form'), function () {
            handleNewsletterEdit($(this));
            setupInterests(function () {});
        });
        handleNewsletterResubscribe($(".js-newsletter-resubscribe-form"));

        newsletterInterestInfoToggle();

        mitTivoliInterests();

        unSubscripteEditInterestsButtonSetup();
    }
    
    function newsletterInterestInfoToggle() {
        $('.js-newsletter-interest-info-toggle').on('click', function() {
            var parentInterest = $(this).closest('.js-newsletter-interest-container');

            if(parentInterest.hasClass('info-is-active')) {
                parentInterest.removeClass('info-is-active');
            } else {
                parentInterest.addClass('info-is-active');
            }
        });
    }

    function handleNewsletterSignup(formContainer) {

        var outerContainer = formContainer.parents(".js-newsletter-form-container");
        var successDiv = outerContainer.find(".form-submit-success");
        var errorDiv = outerContainer.find(".form-submit-failed");
        var alreadyCreatedDiv = outerContainer.find(".form-submit-already-created");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function() {
            successDiv.hide();
            errorDiv.hide();
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        formContainer.on("form-success", function (e, data) {
            if (data.AlreadySignedUp) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                $(".newsletter-signup-editbutton").attr("href", "");
                successDiv.hide();
                errorDiv.hide();
                alreadyCreatedDiv.show(); 
                formContainer.hide(); 
            } else if (data.ChangeSettingsUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                $(".newsletter-signup-editbutton").attr("href", data.ChangeSettingsUrl);
                if (outerContainer.hasClass('js-newsletter-form-container-sticky')) {
                    dataLayer.push({ 'event': 'newsletter-signup-sticky' });
                } else {
                    dataLayer.push({ 'event': 'newsletter-signup' });
                }
                
                successDiv.show();
                errorDiv.hide();
                alreadyCreatedDiv.hide();
                formContainer.hide();
            } else {
                Tivoli.CircleLoader.reset(loader);
                successDiv.hide();
                errorDiv.show();
                alreadyCreatedDiv.hide();
            }
        });
        
        formContainer.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
        });
    };
    
    function handleNewsletterEdit(formContainer) {
        var outerContainer = formContainer.parents(".js-newsletter-form-container");
        var successText = formContainer.data("success-text");
        var errorText = formContainer.data("error-text");

        var buttons = outerContainer.find(".js-custom-submit a");
        buttons.on("click", function(e) {
            e.preventDefault();
            if (formContainer.valid()) {
                formContainer.submit();
            }
        });

        var loaders = buttons.find(".js-circle-loader");

        formContainer.on("form-request", function (e, request) {
            request.ExternalId = Tivoli.getUrlParameterByNameInsensitive("externalid");
            request.ContactId = Tivoli.getUrlParameterByNameInsensitive("contactid");
            request.VisualId = Tivoli.getUrlParameterByNameInsensitive("visualid");
            request.Value = Tivoli.getUrlParameterByNameInsensitive("value");

            var interests = outerContainer.find(".js-newsletter-interest");
            var interestsIsSelected = [];
            $.each(interests, function () {
                if ($(this).is(":checked")) {
                    interestsIsSelected.push($(this).data("id"));
                }
            });
            request.Form.Topics = interestsIsSelected;

            $.each(buttons, function () { Tivoli.ButtonMessage.removeMessage($(this)); });
            $.each(loaders, function () { Tivoli.CircleLoader.loadInitial($(this), null); });
        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                $.each(loaders, function () {
                    var loader = $(this);
                    Tivoli.CircleLoader.loadFinish(loader, function () {
                        Tivoli.CircleLoader.reset(loader);
                    });
                });
                $.each(buttons, function () { Tivoli.ButtonMessage.showMessageDefault($(this), successText); });
               
            } else {
                $.each(loaders, function () { Tivoli.CircleLoader.reset($(this)); });
                
                $.each(buttons, function () { Tivoli.ButtonMessage.showErrorMessageDefault($(this), errorText); });
            }
        });

        formContainer.on("form-error", function () {
            $.each(loaders, function () { Tivoli.CircleLoader.reset($(this)); });
        });
    }

    function setupInterests(onChange) {
        $.each($(".js-newsletter-interest-container"), function () {
            var container = $(this);
            var isSelected = container.hasClass("selected");
            var checkbox = container.find("input");
            container.find(".newsletter-interest-action").on("click", function () {
                checkbox.attr('checked', !isSelected);
                $(this).attr('aria-checked', !isSelected);
                isSelected = !isSelected;
                if (isSelected) {
                    container.addClass("selected");
                } else {
                    container.removeClass("selected");
                }
                onChange(checkbox.data("id"), isSelected, container);
            });
        });

        $(".js-unsubscribe-all").on("click", function (e) {
            e.preventDefault();
            $.each($(".js-newsletter-interest-container"), function () {
                var container = $(this);
                container.find("input").attr('checked', false);
                container.removeClass("selected");
            });

            return;
        });
    }

    function mitTivoliInterests() {
        function setSelected(container, isSelected) {
            container.find("input").attr('checked', isSelected);
            if (isSelected) {
                container.addClass("selected");
            } else {
                container.removeClass("selected");
            }
        }

        $.each($('.js-mit-tivoli-interests'), function () {
            var definitionId = $(this).data("definition-id");
            var profileId = $(this).data("profile-id");
            var endpoint = "/MitTivoli/Account/ChangeInterest";
            if (profileId) {
                endpoint = endpoint + "?profileid=" + profileId;
            }
            setupInterests(function (id, isSelected, container) {
                $.ajax({
                    url: endpoint,
                    traditional: true,
                    data: JSON.stringify({ id: id, isSelected: isSelected, definitionId: definitionId }),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    error: function (err) {
                        setSelected(container, !isSelected);
                        console.error("Error: " + err);
                    },
                    success: function (data) {
                        if (!data.Succeeded) {
                            setSelected(container, !isSelected);
                        }
                        console.log("Interest save", data);
                    },
                    type: "post"
                });
            });
        });
    }
    
    function handleNewsletterResubscribe(formContainer) {
        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function (e, request) {
            request.ExternalId = Tivoli.getUrlParameterByNameInsensitive("externalid");
            request.ContactId = Tivoli.getUrlParameterByNameInsensitive("contactid");
            request.VisualId = Tivoli.getUrlParameterByNameInsensitive("visualid");
            request.Value = Tivoli.getUrlParameterByNameInsensitive("value");
            request.Interest = Tivoli.getUrlParameterByNameInsensitive("interest");

            Tivoli.ButtonMessage.removeMessage(button);
            Tivoli.CircleLoader.loadInitial(loader, null);
        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                window.location.href = formContainer.data("url") + location.search;
            } else {
                Tivoli.CircleLoader.reset(loader);
                Tivoli.ButtonMessage.showErrorMessageDefault(button, null);
            }
        });

        formContainer.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
            Tivoli.ButtonMessage.showErrorMessageDefault(button, null);
        });
    }

    function unSubscripteEditInterestsButtonSetup() {
        var editButton = $('.js-unsubscribe-edit-interests');
        if (editButton.length === 0) {
            return;
        }

        var editButtonUrl = $(editButton).attr('href');
        editButton.attr('href', editButtonUrl + location.search.toLowerCase());
    }

    $(document).ready(init);

    return {
        
    };
})();
