var Tivoli = $.extend(Tivoli, {
    Contact: {}
});
Tivoli.Contact = $.extend(Tivoli.Contact, {
    NewsletterSignup: function (options) {
        var self = this;
        var service = new Service;

        var cnt = null;

        var defaultOptions = {

        };

        this.options = $.extend(defaultOptions, options);

        this.init = function (container) {
            cnt = container;

            cnt.on("click", ".subscribe", function (event) {
                event.preventDefault();
                $(cnt).submit();
                var newslettersignup = {
                    FirstName: cnt.find("#FirstName").val(),
                    LastName: cnt.find("#LastName").val(),
                    Email: cnt.find("#Email").val()
                };
                service.invoke(options.urls.NEWSLETTER_SUBMIT, newslettersignup, function (response) {
                    if (response.Succes) {
                        var result = cnt.find("#result");
                        result.show();
                        result.fadeOut("10000", function () { });
                    }
                    
                }, false);
            });

            this.handleResponse = function (response) {
                if (response.Success) {
                    self.showSuccess(response.Message);
                } else {
                    self.showErrors(response.Message);
                }
            };
            
            this.showErrors = function (message) {};

            this.showWarnings = function (message) {};

            this.showSuccess = function (message) { };

        };
    },
});
