var tivoli = tivoli || {};

Tivoli.iframe = (function() {
    function init() {
        $.each($('.iframe'), function () {
            resizeIframeOnChange(this);
        });
    }


    function resizeIframe(iframe) {
            iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
    }

    function resizeIframeOnChange(iframe) {
        var lastHeight = 0, curHeight = 0;
        setInterval(function() {
            curHeight = iframe.contentWindow.document.body.offsetHeight;
            if (curHeight != lastHeight) {
                $('.iframe').css('height', curHeight + 100 + 'px');
                lastHeight = curHeight;
            }
        }, 500);
    }

    $(document).ready(function () {
        init();
    });
})();


