var Tivoli = Tivoli || {};

Tivoli.upload = function () {
    var $form;
    var uploadButton;
    var texts;
    var progressBar;
    var validator;
	var supportFileUpload = Modernizr.file;
	var uploadInProgress = false;
	var uploadComplete = false;
	var uploadError = false;
    var uploader = {};

	function init() {
	    initializeMoxieUpload();
	    
	    $('a.share').click(function (e) {

	        //We tell our browser not to follow that link
	        e.preventDefault();
	        //We trigger a new window with the Twitter dialog, in the middle of the page
	        window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location, 'facebook', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
	    });

	    $form = $(".js-show-form");
	    texts = $(".application-texts");
	    uploadButton = $(".js-file-upload");
	    
		if($('.js-file-upload').length === 0 ) {
			return;
		}

		$("#pickfiles").on("click", function (e) { e.preventDefault(); });
		$('input[type=file]').change(function () {
		    alert($(this).val());
		});

		uploadButton.on("click", function (e) {
		    e.preventDefault();
		    if ($form.valid()) {
		        startProgress();
		        uploader.start();
		    }
		});
		$("#container div").css({ "z-index": 9999 });
		$("#container div input").css({ "z-index": 9999 });
	}

	function startProgress() {
		if(!uploadInProgress) {
			uploadInProgress = true;
            var loadingText = texts.data("text-uploading");
			var progressBarHtml = "<div class='progressbar'></div>"; // To get progressbar effect manipulate width of this element (to 100%)
            uploadButton.append(progressBarHtml);
            if (uploadButton.find(".load-text").length === 0) {
			    uploadButton.find("span").hide().after('<span class="load-text">' + loadingText + '</span>');
			};
		}
	}

    function postForm() {
	    var request = Tivoli.validation.createFormRequest($form, "");
	    var action = $form.attr("action");
	    var type = $form.attr("method");
	    if (!action || !type) {
	        return;
	    }

	    $.ajax({
	        url: action,
	        traditional: true,
	        data: JSON.stringify(request),
	        dataType: "json",
	        contentType: "application/json; charset=utf-8",
	        error: function (err) {
	            error();
	        },
	        success: function (data) {
	            if (data && !data.Success) {
	                error();
	            } else {
	                showApplicationFormReceipt();
	            }
	        },
	        type: type
	    });
	}

	function reset() {
	    uploadButton.find("span").show();
	    uploadButton.find(".load-text").remove();
	    uploadButton.find(".error-text").remove();
	    uploadButton.find(".progressbar").remove();

		uploadInProgress = false;
		uploadComplete = false;
		uploadError = false;
	}

	function error() {
	    var errorText = texts.data("text-error");

		if(!uploadError) {
		    uploadButton.find(".load-text").remove();
		    uploadButton.find("span").hide().after('<span class="error-text">' + errorText + '</span>');
            uploadError = true;
		}
	}

	function uploadSucces() {
		uploadComplete = true;
	    postForm();
	}

	function showApplicationFormReceipt() {
	    $(document).trigger("uploadSucces");
	    clearForm();
	    reset();
	}

	function clearForm() {
	    $("#selectedfile").html("");
	    $form.find(".js-validation-input").each(function () {
	        if ($(this).is("input:checkbox")) {
	            $(this).prop('checked', false);
	        }
	        $(this).val("");
	    });
	}

	function initializeMoxieUpload()
	{
	    uploader = new plupload.Uploader({
        // General settings
	    runtimes: 'html5,flash,silverlight,html4',
	    browse_button: 'pickfiles',//'js-file-upload', // you can pass in id...
	    container: 'container',
        url: '/Features/File/Upload',
        chunk_size: '1mb', //100k,
        unique_names: true,
        multipart: true,
        multi_selection: false,
        dragdrop: false,  
        // Resize images on client-side if we can
        resize: { width: 320, height: 240, quality: 90 },
        flash_swf_url: '/js/tivoli-responsive/vendor/ie9/Moxie.swf',
        silverlight_xap_url: '/js/tivoli-responsive/vendor/ie9/Moxie.xap',
        filters: {
            max_file_size: '500mb',

            // Specify what files to browse for
            mime_types: [{ title: "Video files", extensions: "MOV,MPEG4,MP4,AVI,WMV,MPEGPS,FLV,3GPP,WebM" }],
                      
        },

       

        // PreInit events, bound before the internal events
        preinit: {
            Init: function(up, info) {
                
            },

            UploadFile: function (up, file) {
                //log('[UploadFile]', file);

                // You can override settings before the file is uploaded
                // up.setOption('url', 'upload.php?id=' + file.id);
                // up.setOption('multipart_params', {param1 : 'value1', param2 : 'value2'});
            }
        },

        // Post init events, bound after the internal events
        init: {
            PostInit: function () {},

            Browse: function(up) {
            },

            //Refresh: function (up) {
            //    // Called when the position or dimensions of the picker change
            //    log('[Refresh]');
            //},

            StateChanged: function (up) {
            },

            //QueueChanged: function (up) {
            //    // Called when queue is changed by adding or removing files
            //    log('[QueueChanged]');
            //},

            //OptionChanged: function (up, name, value, oldValue) {
            //    // Called when one of the configuration options is changed
            //    log('[OptionChanged]', 'Option Name: ', name, 'Value: ', value, 'Old Value: ', oldValue);
            //},

            //BeforeUpload: function (up, file) {
            //    // Called right before the upload for a given file starts, can be used to cancel it if required
            //    log('[BeforeUpload]', 'File: ', file);
            //},

            UploadProgress: function (up, file) {
                uploadButton.find(".progressbar").css("width", up.total.percent + "%");
                // Called while file is being uploaded
                //log('[UploadProgress]', 'File:', file, "Total:", up.total);
            },

            //FileFiltered: function (up, file) {
            //    // Called when file successfully files all the filters
            //    log('[FileFiltered]', 'File:', file);
            //},

            FilesAdded: function (up, files) {
                $("#selectedfile").append(files[0].name);
            },

            //FilesRemoved: function (up, files) {
            //    // Called when files are removed from queue
            //    log('[FilesRemoved]');

            //    plupload.each(files, function (file) {
            //        log('  File:', file);
            //    });
            //},

            FileUploaded: function (up, file, info) {
                // Called when file has finished uploading
                //log('[FileUploaded] File:', file, "Info:", info);
                var response = JSON.parse(info.response);
                if (response.Success) {
                    var filepath = response.FilePath;
                    $("#LocalVideoServerPath").val(filepath);
                    uploadSucces();
                } else {
                    error();
                }
               
            },

            //ChunkUploaded: function (up, file, info) {
            //    // Called when file chunk has finished uploading
            //    //log('[ChunkUploaded] File:', file, "Info:", info);
            //},

            //Is called no matter if its succeeded or not
            UploadComplete: function (up, files) {
                //reset(uploadButton);
                //log('[UploadComplete]');
            },

            //Destroy: function (up) {
            //    // Called when uploader is destroyed
            //    log('[Destroy] ');
            //},

            Error: function (up, args) {
                error();
            }
        }
    });

	    uploader.init();
	  
	   
	}

    $(document).ready(function () {
	    init();
	    
	});
}();