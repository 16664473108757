var Tivoli = Tivoli || {};

Tivoli.Frontpage = (function () {
    var fields = {
        selector: ".hero-section-container"       
    };

    var init = function () {
        var heroSections = $(fields.selector);
        if (heroSections.length <= 0) {
            return;
        }

        var video = heroSections.find(".hero-media-video"),
            image = heroSections.find(".hero-background");

        if (Tivoli.vars.isAnyTouchDevice && video.length > 0) {
            video.hide();
        } else if (video.length > 0) {
            var videoLink = video.find(".hero-video").attr("data-videoLink");
            video.find(".hero-video source").attr("src", videoLink);
            image.hide();
        }
    };

    $(document).ready(function () {
        init();
    });
})();