"use strict";

var Tivoli = Tivoli || {};

/*Tivoli.map = function () {

	var mapSection = $('.map-section');

	$.fn.setupmap = function () {
		return this.each(function () {
		    var map = mapSection.find('.map');
		    if (map.length <= 0) {
		        return;
		    }

			var mapY = map.data('y-coordinate');
			var mapX = map.data('x-coordinate');
			var imgWidth = map.data('img-width');
			var imgHeight = map.data('img-height');

			var yPosCorrection = 7;
            var newPosX = (((mapX / imgWidth)) * 100);
            var newPosY = (((mapY / imgHeight)) * 100) + (yPosCorrection);

			map.css({
				transform: 'translateX(' + -newPosX + '%) translateY(' + -newPosY + '%)'
			});
		});
    };


	function init() {
	    if (mapSection.length > 0) {
	        mapSection.setupmap();
	    }
	}

	$(document).ready(function () {
	    init();
	});
}();*/

Tivoli.fullmap = (function () {

    var scrollOverflowContainer,
        scrollContainer,
        scrollContainerOverflow = "hidden",
        container,
        innerContainer,
        innerContainerHeight,
        loaderContainer,
        mapFooter,
        map,
        center,
        defaultZoomLevel = 17,
        maxZoomLevel = 20,
        minZoomLevel = 6,
        iconHeight = 84, // In px

        infoboxes = [],
        currentInfoBox,

        markers = [],
        currentMarker,
        markerUrlPostfix = "",
        largestUsedZIndex = 1,
        currentPosition = null,

        tabsContainer,
        contentContainer,

        fullScreenAnimationTimePerPx = 1.5;

    function loadMap() {
        $('.js-map-full-screen-toggle').on('click', function () {
            TweenMax.to($('.map-full-screen-toggle__icon-container'), 0.1, { opacity: 0 });
            $('.map-full-screen-toggle__label').hide();
            $('.map-full-screen-toggle__icon-loader').show();
            $('.js-map-full-screen-toggle').unbind();
            
            dataLayer.push({ 'event': 'google-maps-activated' });

            var cultureInfo = $('.js-google-map').attr('data-langauge');
            $.getScript("https://maps.googleapis.com/maps/api/js?sensor=true&language=" + cultureInfo + "&key=AIzaSyBn75ej1X8aMftfKn0KzThIFC74BUt9fM8", function () {
                $.when(
                    $.getScript("/dist/js/geo.js"),
                    $.Deferred(function (deferred) {
                        $(deferred.resolve);
                    })
                ).done(function () {

                    var fullMapContainer = $(".map-section-full");
                    if (fullMapContainer.length !== 0) {
                        setupFullMap();
                        if (!Tivoli.vars.isAnyTouchDevice) {
                            TweenMax.to($('.js-map-full-screen-toggle'), 0.5, 
                                { opacity: 0,  
                                  onComplete: function(){$('.js-map-full-screen-toggle').hide();}
                                }
                            );
                        }
                    }

                    var simpleMapContainer = $(".map-section");
                    if (simpleMapContainer.length !== 0) {
                        setupSimpleMap();
                        map.addListener('tilesloaded', function () {
                            TweenMax.to($('.js-map-full-screen-toggle'), 0.5, 
                                {   opacity: 0,  
                                    onComplete: function(){$('.js-map-full-screen-toggle').hide();}
                                }
                            );
                        })
                    }

                });
            });
        });
    }

    function setupSimpleMap() {
        var c = $(".map-section");
        if (c.length === 0) {
            // No simple location map
            return;
        }
        container = c;

        center = new google.maps.LatLng(55.673467, 12.568524);
        currentPosition = center;

        getBaseContainers();

        createSimpleMap();
        styleMap(map);

        getLocations(container);

        if (Tivoli.settings.width.small >= $(window).width()) {
            map.setOptions({ minZoom: 17, maxZoom: 17, zoom: 17 });
        }
        $(window).resize(function () {
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });
    }

    function setupFullMap() {
        var c = $(".map-section-full");
        if (c.length === 0) {
            // No full location map
            return;
        }

        container = c;

        center = new google.maps.LatLng(55.673467, 12.568524);
        currentPosition = center;

        getBaseContainers();

        tabsContainer = container.find(".js-page-filter-tab-navigation");
        contentContainer = $(".map-section-full__content-container .ajax-content");
        loaderContainer = $('.map-section-full__content-container .loader-container');
        mapFooter = container.find(".map-footer");

        createMap();
        styleMap(map);
        handleTabs();
        handleShowMoreButton();

        if (Tivoli.vars.isAnyTouchDevice) {
            handleFullScreen();
        } else {
            handleZooming();
        }

        getPosition();

        $(window).resize(function () {
            google.maps.event.trigger(map, "resize");
        });
    }

    function getBaseContainers() {
        scrollOverflowContainer = $("html");
        scrollContainer = $("#content-container");
        innerContainer = $(".map-section__inner-container");
    }

    function createMap() {
        var myOptions = {
            center: center,
            zoomControl: false,
            panControl: false,
            zoom: defaultZoomLevel,
            draggable: true,
            minZoom: minZoomLevel,
            maxZoom: maxZoomLevel,
            mapTypeControl: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            disableDoubleClickZoom: false,
            scrollwheel: false,
        };

        map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
    }

    function createSimpleMap() {
        var myOptions = {
            center: center,
            zoomControl: false,
            panControl: false,
            zoom: 18,
            draggable: false,
            disableDefaultUI: true,
            scrollwheel: false,
            minZoom: 18,
            maxZoom: 18,
            mapTypeControl: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            disableDoubleClickZoom: true
        };

        map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
    }

    function styleMap(m) {
        m.set('styles', [{ "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "color": "#b4c194" }, { "visibility": "on" }] }, { "featureType": "poi", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.local", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.local", "elementType": "geometry.stroke", "stylers": [{ "color": "#839061" }] }]);
    }

    function handleTabs() {
        var tabs = $(".full-map-filter-controls .tab-filter");
        var button = $(".map-extended-search-container");

        var startTab = Tivoli.getUrlParameterByName("tab");
        if (startTab) {
            $.each(tabs, function () {
                if ($(this).data("id") === startTab) {
                    // Start tab found
                    getTab($(this), false, true);
                    button.hide();
                }
            });
        } else {
            // Default to first
            if (tabs.length > 0) {
                getTab($(tabs.get(0)), false, false);
            }
        }

        tabs.on("click", function () {
            getTab($(this), true, false);
            button.show();
        });
    }

    function handleShowMoreButton() {
        var buttonContainer = $(".map-extended-search-container");
        var button = $(".map-extended-search");

        button.on("click", function () {
            var tab = $(".full-map-filter-controls .tab-filter.js-selected");
            getTab(tab, true, true);
            buttonContainer.hide();
        });
    }

    function getLocations(dataContainer) {
        var itemIds = dataContainer.data("ids");
        if (!itemIds) {
            return;
        }

        itemIds = itemIds.split("|");

        $.ajax({
            url: "/Features/Map/GetMarkers",
            traditional: true,
            data: $.param({ itemIds: itemIds }, true),
            error: function (err) {
                console.log(err);
            },
            success: function (data) {
                common.clearMap(false, false);
                for (var i = 0; i < data.length; i++) {
                    var loc = data[i];
                    createMarker(loc, false, true);
                }
                if (data.length > 0) {
                    center = new window.google.maps.LatLng(data[0].Latitude, data[0].Longitude);
                    map.setCenter(center);
                }
            },
            type: 'GET'
        });
    }

    function getTab(tab, renderContent, showAll) {
        var itemId = tab.data("itemid");

        if (!itemId) {
            return;
        }

        searchStart();

        $.ajax({
            url: "/Features/Map/GetLocationsWithType",
            traditional: true,
            data: $.param({ itemId: itemId, showAll: showAll }, true),
            error: function (err) {
                searchEnd();
                console.log(err);
            },
            success: function (data) {
                searchEnd();
                common.clearMap(renderContent, true);
                for (var i = 0; i < data.length; i++) {
                    var loc = data[i];
                    createMarker(loc, true, false);
                    if (renderContent) {
                        addContentRow(loc);
                    }
                }
                map.setCenter(center);
                map.setZoom(defaultZoomLevel);
            },
            type: 'GET'
        });
    }

    function searchStart() {
        var anyContent = contentContainer.html().trim().length > 0;
        if (anyContent) {
            loaderContainer.removeAttr("style");
        } else {
            // Make sure loader is shown when no content
            loaderContainer.height("80px");
        }

        contentContainer.addClass('loader-running');
        loaderContainer.addClass('js-loader-active');
    }

    function searchEnd() {
        contentContainer.removeClass('loader-running');
        loaderContainer.removeClass('js-loader-active');
    }

    function createMarker(data, includeInfobox, simpleMarker) {
        var location = new window.google.maps.LatLng(data.Latitude, data.Longitude);
        var marker = new window.google.maps.Marker({
            position: location,
            clickable: !simpleMarker,
            map: map
        });
        marker.locationData = data;
        marker.setZIndex(1);
        common.setMarkerIcon(marker, false);

        markers.push(marker);

        if (includeInfobox) {
            var hasLink = !!data.Url;
            var linkTagType = hasLink ? "a" : "span";
            var href = hasLink ? "href='" + data.Url + "'" : "";
            // Subtracting 0.4 seems to remove all rounding bugs -> 0.3 and 0.5 is too less and too much for certain markers in certain zoom levels
            var pixelOffset = new google.maps.Size(26, -iconHeight - 0.4);
            var boxOptions = {
                content: "<div class='map-marker-infobox' style='height:" + (iconHeight - 2) + "px;'><" + linkTagType + " class='map-box-label' " + href + ">" + data.Title + "</" + linkTagType + ">" + (hasLink ? "<span class='map-box-divider'></span><a class='map-box-arrow' " + href + "><i class='icon-arrow-right' /></a>" : "") + "</div>",
                disableAutoPan: false,
                pixelOffset: pixelOffset,
                isHidden: false,
                boxStyle: {
                    opacity: 1
                },
                closeBoxURL: "",
                pane: "floatPane",
                enableEventPropagation: false
            };

            var infobox = new InfoBox(boxOptions);
            infoboxes.push(infobox);
        }

        if (simpleMarker) {
            return;
        }

        window.google.maps.event.addListener(marker, 'click', function () {
            if (currentMarker == marker) {
                if (infobox) {
                    common.closeInfoBox(infobox);
                    currentInfoBox = null;
                }

                common.setMarkerIcon(currentMarker, false);
                currentMarker = null;
            } else {
                if (infobox) {
                    common.closeInfoBox(currentInfoBox);
                    infobox.open(map, marker);
                    currentInfoBox = infobox;
                }

                common.setMarkerIcon(currentMarker, false);
                common.setMarkerIcon(marker, true);
                marker.setZIndex(largestUsedZIndex + 1);
                currentMarker = marker;
                largestUsedZIndex++;
            }
        });
    }

    function addContentRow(data) {
        contentContainer.append(data.ListItem);
    }

    /************************* POSITION *****************************/

    function getPosition() {
        var geoMarker = new GeolocationMarker();
        geoMarker.setCircleOptions({ fillColor: '#000000', fillOpacity: 0.05 });
        //geoMarker.setMarkerOptions({ icon:  });

        google.maps.event.addListener(geoMarker, 'position_changed', function () {
            currentPosition = this.getPosition();
        });

        geoMarker.setMap(map);

        /*navigator.geolocation.watchPosition(function() {
            alert("Found");
        }, function (error) {
            alert(JSON.stringify(error));
        }, {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 0
        });*/
    }

    /******************************************************************/

    var common =
    {
        clearMap: function (clearContent, includeInfoboxes) {
            if (includeInfoboxes) {
                for (var i = infoboxes.length - 1; i >= 0; i--) {
                    var ib = infoboxes[i];
                    ib.setMap(null);
                    infoboxes.splice(i, 1);
                }
                currentInfoBox = null;
            }

            for (var j = markers.length - 1; j >= 0; j--) {
                var m = markers[j];
                m.setMap(null);
                markers.splice(j, 1);
            }
            currentMarker = null;


            if (clearContent) {
                contentContainer.empty();
            }
        },
        closeAllInfoBoxes: function () {
            for (var i = 0; i < infoboxes.length; i++) {
                common.closeInfoBox(infoboxes[i]);
            }
        },
        closeInfoBox: function (infobox) {
            if (infobox != null && infobox.getMap()) {
                infobox.close();
            }
        },
        makeMarkersInactive: function () {
            for (var i = 0; i < markers.length; i++) {
                var m = markers[i];
                common.setMarkerIcon(m, false);
            }
        },
        setMarkerIcon: function (marker, active) {
            if (!marker) {
                return;
            }
            if (active) {
                marker.setIcon(marker.locationData.ActiveMarker + markerUrlPostfix);
            } else {
                marker.setIcon(marker.locationData.InactiveMarker + markerUrlPostfix);
            }
        }
    };

    function handleZooming() {
        var controls = container.find(".map-controls");
        controls.show();

        var myposBtn = controls.find(".js-map-mypos");
        var zoomInBtn = controls.find(".js-map-zoom-in");
        var zoomOutBtn = controls.find(".js-map-zoom-out");

        myposBtn.on("click", function () {
            if (currentPosition) {
                map.setCenter(currentPosition);
            }
        });
        zoomInBtn.on("click", function () {
            map.setZoom(map.getZoom() + 1);
        });
        zoomOutBtn.on("click", function () {
            map.setZoom(map.getZoom() - 1);
        });
    }

    function handleFullScreen() {
        var fullScreenToggle = innerContainer.find(".js-map-full-screen-toggle");
        var fullScreenClose = innerContainer.find(".js-map-full-screen-close");
        //        fullScreenToggle.show();

        //       fullScreenToggle.on("click", function () {
        innerContainerHeight = innerContainer.height();

        var menuHeight = $("#head-section").outerHeight();
        var tabsHeight = tabsContainer.outerHeight();
        var mapFooterHeight = mapFooter.outerHeight();

        var currentPos = scrollContainer.scrollTop();
        var neededPos = container.offset().top - menuHeight;
        var animateTimePos = Math.max(Math.min(Math.abs(currentPos - neededPos) * fullScreenAnimationTimePerPx, 1000), 500);

        var currentHeight = container.outerHeight();
        var neededHeight = $(window).outerHeight() - menuHeight;
        var animateTimeHeight = Math.max(Math.min(Math.abs(currentHeight - neededHeight) * fullScreenAnimationTimePerPx, 1000), 500);

        var animateTime = Math.max(animateTimePos, animateTimeHeight);

        var refreshMap = setInterval(function () {
            google.maps.event.trigger(map, "resize");
        }, 5);

        innerContainer.animate({
            height: neededHeight - tabsHeight - mapFooterHeight
        }, animateTime, function () {
            innerContainer.removeAttr("style");

            clearInterval(refreshMap);

            container.addClass("fullscreen");
            container.css("padding-top", menuHeight);
            innerContainer.css("padding-top", tabsHeight);
            innerContainer.css("padding-bottom", mapFooterHeight);

            google.maps.event.trigger(map, "resize");
            scrollContainerOverflow = scrollOverflowContainer.css("overflow");
            scrollOverflowContainer.css("overflow", "hidden");
        });

        $('body').animate({
            scrollTop: neededPos

        }, animateTime);


        fullScreenToggle.animate({
            opacity: 0
        }, animateTime, function () {
            fullScreenToggle.hide();
        });

        setTimeout(function () {
            fullScreenClose.show();
            fullScreenClose.css("bottom", mapFooterHeight);
            fullScreenClose.css("opacity", 0);
            fullScreenClose.animate({
                opacity: 1
            }, 200);
        }, animateTime);
    }


    function fullMapClose() {
        var innerStartHeight = innerContainer.height();
        var innerNeededHeight = innerContainerHeight;
        var animateTimeHeight = Math.max(Math.min(Math.abs(innerStartHeight - innerNeededHeight) * fullScreenAnimationTimePerPx, 1000), 500);

        container.removeClass("fullscreen");
        container.removeAttr("style");
        innerContainer.removeAttr("style");

        innerContainer.height(innerStartHeight);

        innerContainer.animate({
            height: innerNeededHeight
        }, animateTimeHeight, function () {
            innerContainer.removeAttr("style");

            google.maps.event.trigger(map, "resize");
            scrollOverflowContainer.css("overflow", scrollContainerOverflow);
        });

        fullScreenToggle.show();
        fullScreenToggle.animate({
            opacity: 1
        }, animateTimeHeight);

        fullScreenClose.hide();
        //}

        fullScreenClose.on("click", fullMapClose);

        $(document).on('menuOpen', fullMapClose);
    }

    $(document).ready(function () {
        if ($(".js-map-full-filtering").length > 0) {
            $('html').addClass('no-hardware-acceleration'); // Remove hardware-performance, to allow animations, positioning and map
        }

        loadMap();
        //setupFullMap();
        //setupSimpleMap();
    });
})();