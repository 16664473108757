var tivoli = tivoli || {};

Tivoli.search = (function () {
	
	function init(){
		if($(".search-modal-section").length === 0) {
			return;
		} 
		
		$('.js-search').on('click', function () {
			openSearchModal();
		});

		$(".ModalSearchForm__close-button").on('click', function () {
			closeSearchModal();
		});

		$(".SearchModalContent").on('click', function (e) {			
			if ($(e.target).hasClass('ModalSearchForm') || $(e.target).hasClass('ModalContent__frame')) {
				closeSearchModal();
			}
		});		
	}

	function openSearchModal() {
		$(".search-modal-section").show();
		$(".ModalSearchForm__input").val("");
		$(".ModalSearchForm__input").focus();		
		document.addEventListener('keydown', function(e) {
			handleKeyPress(e);
		});
	}

	function handleKeyPress(e){
		var keyName = e.key;
		if (keyName === 'Escape') {
		 closeSearchModal();
		}
	}

	function closeSearchModal() {
		pushDigitalData();
		$(".search-modal-section").hide();
		document.removeEventListener('keydown', function(e) {
			handleKeyPress(e);
		});
	}

	function pushDigitalData() {
		Tivoli.Shop.Tracking.digitalDataSearch(window.CludoSearch.totalDocuments, window.CludoSearch.params.query);
	}

	$(document).ready(function () {
	    init();
	});

	return {
	   close : closeSearchModal
    };
})();