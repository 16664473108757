var Tivoli = Tivoli || {};
Tivoli.VertigoKiosk = (function () {
    var vertigoKioskGifContainer = $(".js-vertigo-gif-kiosk-images");


    function init() {
        if (vertigoKioskGifContainer.length === 0) {
            return;
        }

      getLatesImages();
    }

   function getLatesImages() {
       $(".js-vertigo-gif-kiosk-images").html('');
        $.ajax({
            url: '/Features/UserMedia/GetLatestMediaFiles?campaignId=vertigo&contentType=image/gif&count=4',
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            error: function () {
                 enableErrorMsg();
            },
            success: function (data) {
                if(data == null || data.length == 0)
                {
                    enableErrorMsg();
                } else {
                    removeErrorMsg();
                    console.log(data);
                    loadNewImages(data);
                }
                
            },
        });

         setTimeout(function () {
            getLatesImages();
        }, 15000);
    }

    function loadNewImages(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                var imgContainer = "<div class='vertigo-gif-kiosk-images__image'><img src="+ data[key].Url +" /><h1>" + data[key].ExternalCode + "</h1></div>";
                $(".js-vertigo-gif-kiosk-images").append(imgContainer);
            }
        }
    }

    function enableErrorMsg() {
        $('.vertigo-gif-kiosk').addClass('vertigo-gif-kiosk--error');
    }

    function removeErrorMsg() {
        $('.vertigo-gif-kiosk').removeClass('vertigo-gif-kiosk--error');
    }
    

    $(document).ready(function () {
        init();
    });

    return {

    };
})();