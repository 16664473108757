var Tivoli = Tivoli || {};

Tivoli.ShareTwitter = (function () {

    function init() {
        var shareTwitterButton = $(".js-share-twitter");

        if (shareTwitterButton.length === 0) {
            return;
        }

        shareTwitterButton.on("click", function (e) {
            e.preventDefault();

            var message = $(this).data("share-message");
            var link = $(this).data("share-link");
            
            window.open('https://twitter.com/share?url=' + escape(link) + '&text=' + escape(message), 'twitter', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        });
    }

    $(document).on("ready", function () {
        init();
    });
})();