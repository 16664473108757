var tivoli = tivoli || {};

Tivoli.printProgram = (function () {

	var $printDayProgramBtn,
		printProgram = false,
		programDate = false;

	function init() {

		$printDayProgramBtn = $('.js-print-program');

		if($printDayProgramBtn.length === 0) {
			return;
		}
		setClickEvent();
		printListener();

	}

	function setClickEvent() {
		$printDayProgramBtn.on('click', function(e) {
			printProgram = true;
			Tivoli.Filter.clearFilters();
			setData($(this));
		});
	}

	function getProgramDate($elem) {
		var programData = $elem.data('program-date');
		return programData;
	}

	function setData($elem) {
		var dateString = getProgramDate($elem);
		var date = moment(dateString, "DD-MM-YYYY").toDate();
		programDate = dateString;
		$('.daterange').daterangepicker( "setRange", {start: date, end: date} );
	}

	function unsetDate() {
		$('.daterange').daterangepicker( "clearRange" );
	}

	function printListener() {
		$(document).one('filterSearchComplete', function () {
			printProgramPage();
		});
	}

	function printProgramPage() {
		if(printProgram) {
			Tivoli.Shop.Tracking.digitalDataProgramPrint(moment(programDate, "DD-MM-YYYY").format('dddd'), moment(programDate, "DD-MM-YYYY").format('MMMM Do YYYY'));
			window.print();
			printProgram = false;

			setTimeout(function() {
				unsetDate();
			}, 500)

		}
	}

	$(document).on('filterSearchComplete', function () {
		init();
	});
})();