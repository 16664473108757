var Tivoli = Tivoli || {};

Tivoli.simpletabs = (function () {
    "use strict";


    
	function init() {

	    //trigger($('.tab-special'));
	  
	}

	function trigger($tabTrigger) {
	    $tabTrigger.on('click', function (e) {
	        $('.tab-special.js-selected').removeClass('js-selected');
	        $(this).addClass('js-selected');

	        $('.promotion--renewpass').hide();

	        var dataId = $(this).data('id');

	        $('[data-id="'+ dataId +'"]').show();
	    });
	}


	$(document).ready(function() {
		init();
	});

    return {
        init: init
    };
})();