var Tivoli = Tivoli || {};

Tivoli.Youtube = (function () {
    var player = null;
    var settings = {
        selector: ".hero-media-container",
        height: '100%',
        width: '100%',
        autoplay: 1, 
        controls: 0, 
        autohide: 1, 
        loop: 1, 
        mute: 1,
        rel: 0,
        iv_load_policy: 3,
        showinfo: 0,
        cc_load_policy: 0,
    };

    /*
        PreCondition element, that transforms into iframe :  <div id="yt-player" data-videoid=""></div>
        Initialize youtube video iframe, to manage videoplayer controls
        options are overridden from the initializer. 
    */
    var init = function () {
        var playerContainer = $(settings.selector).find("#yt-player");

        $.extend(settings, { mute: $(playerContainer).data("mute"), loop: $(playerContainer).data("loop") });

        

        window.onYouTubeIframeAPIReady = function () {
            player = new YT.Player('yt-player', {
                height: settings.height,
                width: settings.width,
                videoId: $(playerContainer).data("videoid"),
                playerVars:
                    {
                        controls: settings.controls,
                        autohide: settings.autohide,
                        loop: settings.loop,
                        rel: settings.rel,
                        iv_load_policy: settings.iv_load_policy,
                        showinfo:settings.showinfo,
                        cc_load_policy: settings.cc_load_policy
                    },
                events: {
                    'onReady': onPlayerStateReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        }
    };

    var onPlayerStateChange = function (event) {
        if (!player) {
            return;
        }
        if (event.data == YT.PlayerState.ENDED && settings.loop) {
           play();
        }
    };

    var onPlayerStateReady = function () {
        if (!player) {
            return;
        }
        play();
    };

    var stop = function () {
        if (!player) {
            return;
        }
        player.stopVideo();
    };

    var pause = function () {
        if (!player) {
            return;
        }
        player.pauseVideo();
    };

    var play = function () {
        if (!player) {
            return;
        }
        player.playVideo();
        mute();
    };

    var mute = function () {
        if (!player && settings.mute == 1) {
            return;
        }
        player.mute();
    };

    var playerIsActive = function() {
        return player != null;
    }

    return {
        init: init,
        playerIsActive: playerIsActive,
        play: play,
        stop: stop,
        pause: pause
    };
})();