var Tivoli = Tivoli || {};

Tivoli.navigation = function () {
/*
    var navWidth = 256,
		menuBreakWidth = Tivoli.settings.width.large,
		animationTiming = Tivoli.settings.animation.timing;

    function init() {
		menuHandle();
		basketActivation();
		exitAllOpenStates();
		topWidgetActivation();
	}

	function topWidgetToggle() {
    	if (!Tivoli.globalStates.state.topWidgetOpen) {
    		Tivoli.globalStates.setState.topWidget.open();
		} else if (Tivoli.globalStates.state.topWidgetOpen) {
			Tivoli.globalStates.setState.topWidget.close();
		}
	}

	function topWidgetActivation() {
		var $topWidgetTrigger = $('.js-open-top-widget');
		var activated;

		$topWidgetTrigger .on('click', function(e) {
			e.preventDefault();

			if(!activated) {
				activated = true;
				setTimeout(function(){ activated = false; }, animationTiming);
				topWidgetToggle();
			}
		});
	}

	function asideContentOpen() {
		if (!Tivoli.globalStates.state.asideOpen) {
			if (Tivoli.globalStates.state.topWidgetOpen) {
				topWidgetToggle();
				setTimeout(function() {
					Tivoli.globalStates.setState.aside.open();
				}, Tivoli.settings.animation.timing);
			} else {
				Tivoli.globalStates.setState.aside.open();
			}
		}
	}

	function basketActivation() {
		var basketTrigger = $('.js-slideout-right-basket');
		var $slideoutRightContainer = $('.slideout-right-section');
		var activated;

		basketTrigger.on('click', function(e) {
			e.preventDefault();

			if ($slideoutRightContainer.length === 0) {
			    return;
			}

			if(!activated) {
				activated = true;
				setTimeout(function(){ activated = false; }, animationTiming);
				asideContentOpen();
			}
		});
	}

    function menuOpen() {
        if (!Tivoli.globalStates.state.menuOpen) {
			Tivoli.globalStates.setState.menu.open();
    	}
	}

	// MENU BUTTON ACTIVIATION
    function menuHandle() {

        var $menuBtn = $('.menu-button');
        var activated;

		$menuBtn.on('click', function (e) {
			e.preventDefault();
      console.log('menuHandle');
			if(!activated) {
				activated = true;
				setTimeout(function(){ activated = false; }, animationTiming);
				menuOpen();
			}
		});
	}

	// EXIT MENU or ASIDE CONTENT
	function exitAllOpenStates() {
    	var $exitNavOverlay = $('.js-menuoverlay-exit');

		$exitNavOverlay.on('click', function() {
			// Navigation can be open while using top widget
			if (Tivoli.globalStates.state.menuOpen && Tivoli.globalStates.state.topWidgetOpen) {
				Tivoli.globalStates.setState.menu.close();
			} else {
				Tivoli.globalStates.setState.closeAll();
			}
		});
	}
*/



	// CLOSE MENU, WHEN MENU-LINKS ARE CLICKED
	// function closeMenuOnClick() {
	// 	var $menuItem = $('.main-nav, .sup-main-nav').find('a').not('a[href*=#]');
	//
	// 	$menuItem.on('click', function(e) {
	// 		if (menuBreakWidth > windowWidth) {
	// 			e.preventDefault();
	// 			var href = $(this).attr('href');
	// 			menuOpen(); // CLOSE MENU, WHEN "NORMAL" MENU-ITEM IS CLICKED
	// 			setTimeout(function() {
	// 				window.location = href; // Wait until animation has been set
	// 			}, 600);
	// 		}
	// 	});
	// }

	// function menuAnchorLinks() {
	// 	var navAnchorLink = $('.main-nav li').find('a[href*="#"]');
	// 	var currentPath = window.location.href.split('#')[0];
	//
	// 	var anchorTarget;
	// 	var animationLength = 550;
	// 	var anchorAnimRunning;
	//
	// 	navAnchorLink.on('click', function(e) {
	// 		var link       = $(this).attr('href');
	// 		var linkArray  = link.split('#');
	// 		var navId      = (typeof linkArray[1] !== 'undefined') ? linkArray[1] : null;
	// 		var targetPath = linkArray[0];
	//
	// 		e.preventDefault();
	//
	// 		if(targetPath === currentPath && navId || targetPath === "") {
	// 		    scrollTarget('#' + navId);
	// 		    $('.main-nav li .is-active').removeClass('is-active');
	// 		    $(this).addClass('is-active');
	// 		} else if (navId) {
	// 			e.preventDefault();
	// 			window.location = targetPath+'#'+navId;
	// 		}
	//
	// 		function scrollTarget(whereTo) {
	// 		    anchorTarget = (Math.abs($body.offset().top)) + ($(whereTo).offset().top - menuTopHeight);
	// 		}
	//
	// 		if(!anchorAnimRunning) {
	// 			anchorAnimRunning = true;
	// 			$bodyhtml.animate({
	// 				scrollTop: anchorTarget
	// 			}, animationLength, function() {
	// 				$html.removeClass('menu-is-active');
	// 				anchorAnimRunning = false;
	// 			});
	// 		}
	// 	});
	// }

    // function externalAnchorLinks() {
    //     var anchorTarget;
    //     var animationLength = 1;
	//
    //     var link = window.location + '';
    //     var linkArray = link.split('#');
    //     var navId = (typeof linkArray[1] !== 'undefined') ? linkArray[1] : null;
	//
    //     if (navId) {
    //         var targetId = $("#" + navId).length !== 'undefined' ? $("#" + navId) : '';
	//
    //         if (targetId.length <= 0) {
    //             return;
    //         }
    //
    //         anchorTarget = (Math.abs($body.offset().top)) + ($('#' + navId).offset().top - menuTopHeight);
	//
    //         $bodyhtml.animate({
    //             scrollTop: anchorTarget
    //         }, animationLength, function() {
    //         });
    //     }
    // }


/*	function menuSize() {
		if (!Tivoli.globalStates.state.menuOpen && Tivoli.vars.windowWidth < menuBreakWidth) {
			return 0;
		}
		return navWidth;
	}

    $(document).ready(function () {
        init();
    });

    return {
        size: menuSize
    };*/
}();