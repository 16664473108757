var Tivoli = Tivoli || {};

Tivoli.accordion = (function () {
    "use strict";

	var mediumBreakPoint = Tivoli.settings.width.medium;
	var windowWidth = Tivoli.vars.windowWidth;
    
	function init() {


	    if ($('.accordion').length === 0) {
	        return;
		}

		$('.accordion').each(function() {
			if( $(this).hasClass('accordion--open-single')) {
				var accordionItems = $(this).find('.accordion-item'),
					hasOnlyOneItem = accordionItems.length === 1;
				
				if(hasOnlyOneItem) {
					var firstItem = accordionItems.first(),
						firstItemHead = firstItem.find('.accordion-head');
						accordionOpenSingle(firstItemHead)
				}
			}
		})

	    trigger($('.accordion').find('li .accordion-head'));

		$(window).smartresize(function() {
			resizeOpenAccordion();
			windowWidth = $(window).width();
		});

	}

	function trigger($accordionTrigger) {
		$accordionTrigger.unbind('click');
		$accordionTrigger.on('click', function (e) {
			e.stopPropagation();
			accordionAnim($(this));
		});
	}

	function accordionOpenSingle(itemHead) {
		var accordionBody = itemHead.siblings('.accordion-body'),
			accordionBodyInner = accordionBody.find('.accordion-body-inner'),
			bodyHeight = accordionBodyInner.outerHeight() + 10 > 70 ? accordionBodyInner.outerHeight() + 10 : 70;

		itemHead.closest('li').addClass("is-active");
		accordionBody.css({
			height: bodyHeight,
			opacity: 1
		});
	}

	function accordionAnim(trigger) {
		var thisAccordianItem =  trigger.closest('li'),
			accordionBody = trigger.siblings('.accordion-body'),
			accordionBodyInner = accordionBody.find('.accordion-body-inner'),
			bodyHeight = accordionBodyInner.outerHeight() + 10 > 70 ? accordionBodyInner.outerHeight() + 10 : 70;


		if(trigger.closest('.accordion--table-view').length > 0 && windowWidth >= mediumBreakPoint) {
			if(thisAccordianItem.hasClass('is-active')) {
				trigger.closest('li').removeClass("is-active");
				accordionBody.css({
					height: '75px'
				});
			} else {
				trigger.closest('li').addClass("is-active");
				accordionBody.css({
					height: bodyHeight
				});
			}
		} else {
			if(thisAccordianItem.hasClass('is-active')) {
				trigger.closest('li').removeClass("is-active");
				accordionBody.css({
					height: '0',
					opacity: 0
				});
			} else {
				trigger.closest('li').addClass("is-active");
				accordionBody.css({
					height: bodyHeight,
					opacity: 1
				});
			}
		}
	}

	function resizeOpenAccordion() {

		var openAccordions = $('.accordion').find('.is-active');
		var openAccordionBody = openAccordions.find('.accordion-body');
		var notOpenAccordions = $('.accordion').find('.accordion-item').not('.is-active');

		openAccordions.each(function() {
			var accordionBody = openAccordions.find('.accordion-body'),
				accordionBodyInner = accordionBody.find('.accordion-body-inner'),
				bodyHeight = accordionBodyInner.outerHeight() + 10 > 75 ? accordionBodyInner.outerHeight() + 10 : 75;

			accordionBody.css({
				height: bodyHeight
			});

			if($(this).is('.accordion--table-view').length > 0) {
				accordionBody.attr('top', 'auto');
			}
		});

		// SET OPACITY to INSURE A ACTIVE ACCORDION IS NOT HIDDEN
		openAccordionBody.css('opacity', 1);
		// CLEAR ALL NON-OPEN ACCORDIONS
		notOpenAccordions.find('.accordion-body').attr('style', '');
	}


	$(document).ready(function() {
		init();
	});

    return {
        init: init
    };
})();