var Tivoli = Tivoli || {};


/***********************************
Author: Lars Sørensen - Tivoli
***********************************/
Tivoli.shows = (function () {

    function init() {
        $.each($('.js-rating-form'), function () {
            userReviewRate($(this));
        });

        if ($('.spamalot--stupid-button').length != 0) {
            stupidOverlay();
        }
    }

    function fadeButton() {
        $("button.button-fade").on("click", function () {
            var expanderId = $(this).data("fader-id");
            var containerToExpand = $("[data-fade-id=" + expanderId + "]");
            var buttonToHide = $(this);
            var openHeight = containerToExpand.children(".text-fade-container").height();
            
            buttonToHide.fadeOut("fast");
            containerToExpand.animate({
                "min-height": openHeight
            }, "slow", function() {
                containerToExpand.css("height", "auto");
                containerToExpand.css("min-height", "0");
                
            });

            containerToExpand.css("-webkit-mask-image", "-webkit-gradient(linear, 0% 0%, 0% 100%, from(black), to(rgba(0, 0, 0, 1)))");
        });
    }

    function castOverlay() {
        $(".cast-overlay-button").on("click", function () {

            $(".popup").hide();
            var expanderId = $(this).data("expander-id");
            var offset = $(this).position();
            var containerToExpand = $("[data-expand-id=" + expanderId + "]");

            containerToExpand.show();
            containerToExpand.css("top", offset.top);
        });

        $(".popup__exit").on("click", function () {
            $(this).parent().hide();
        });
    }

    function stupidOverlay() {
        $(".spamalot--stupid-button a").click(function (e) {
            e.preventDefault();
            var containerToExpand = $(".spamalot--stupid-button .stupid-popup");

            containerToExpand.show();
        });

        $(".exit-popup").on("click", function () {
            $(this).parent().hide();
        });
    }

    function userReviewStarWidth() {
        $(".star-container .star").each(function () {
            var width = $(this).data("width");
            $(this).css("width", width + "%");
        });
    }

    function GetURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }

    function userReviewRate(formContainer) {
        $(".user-reviews--rate .star-container").on("click", function () {
            var starNum = $(this).children(".star").data("star-num");
            userReviewSetStars(starNum);
        });

        $(".user-reviews--rate .star-container").mouseenter(function () {
            var starNum = $(this).children(".star").data("star-num");
            $(".user-reviews--rate .star").each(function (index, value) {
                if ((index + 1) <= starNum) {
                    $(this).css("width", "100%");
                } else {
                    $(this).css("width", "0%");
                }
            });
        }).mouseleave(
            function() {
                var starNum = $('input#RatingValue').val();
                $(".user-reviews--rate .star").each(function(index, value) {
                    if ((index + 1) <= starNum) {
                        $(this).css("width", "100%");
                    } else {
                        $(this).css("width", "0%");
                    }
                });
            });
    

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function () {
            Tivoli.CircleLoader.loadInitial(loader, null);
        });

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                button.hide();
                $('.js-message-box').html($(".js-message-box--success").html());
            } else {
                button.hide();
                $('.js-message-box').html(data.Error);
                $('.js-message-box').addClass("error error--no-border");
            }
        });

        formContainer.on("form-error", function (e) {
            button.hide();
            $('.js-message-box').html(data.Error);
        });
    }

    function userReviewSetStars(star)
    {
        $(".user-reviews--rate .star").each(function (index, value) {
            if ((index + 1) <= star) {
                $(this).css("width", "100%");
            } else {
                $(this).css("width", "0%");
            }
        });

        $('input#RatingValue').val(star);
    }


    $(document).on("ready", function () {
        var sliderHeight = "200px";
        $(".text-fade").css("height", sliderHeight);
        $(".text-fade").css("position", "relative");

        fadeButton();

        castOverlay();

        userReviewStarWidth();

        init();

        if (GetURLParameter('stars') != null) {
            userReviewSetStars(GetURLParameter('stars'));

            $('.rate').addClass('rate--open');
            $('.rate-dimmed').addClass('rate-dimmed--open');

            $('.rate-close').on("click", function() {
                $('.rate').removeClass('rate--open');
                $('.rate-dimmed').removeClass('rate-dimmed--open');
            });

            $('.rate-dimmed').on("click", function () {
                $('.rate').removeClass('rate--open');
                $('.rate-dimmed').removeClass('rate-dimmed--open');
            });
        }
    });

}());
