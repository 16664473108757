var Tivoli = Tivoli || {};
Tivoli.VertigoGif = (function () {
    var images = $(".vertigo-gif-image-stack img"),
        vertigoGifContainer = $(".vertigo-gif-image-stack"),
        startImage = "";


    var smallBreak = Tivoli.settings.width.small;

    function init() {

        if (vertigoGifContainer.length === 0) {
            return;
        }

        initStack();
        getLatesImages();
    }

    function getLatesImages() {
        $.ajax({
            url: '/Features/UserMedia/GetLatestMediaFiles?campaignId=vertigo&contentType=image/gif&count=5',
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            error: function () {
                enableErorrMsg();
            },
            success: function (data) {
                if(data == null || data.length == 0) {
                    enableErorrMsg();
                }
                loadNewImages(data);
            },
        });
    }

    function loadNewImages(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                var img = $("<img />").attr('src', data[key].Url)
                $(".vertigo-gif-image-stack").append(img);
            }
        }
    }

    function initStack() {
        $(".vertigo-gif-image-stack__next--js").on("click", function () {
            if (!TweenMax.isTweening($('.vertigo-gif-image-stack img:nth-child(4)'))) {
                swipeStack(true);
            }
        });

        $(".vertigo-gif-image-stack__prev--js").on("click", function () {
            if (!TweenMax.isTweening($('.vertigo-gif-image-stack img:nth-child(4)'))) {
                swipeStack(false);
            }
        });

        var ts;
        $(".vertigo-gif-image-stack").on("touchstart", function (e) {
            ts = e.originalEvent.touches[0].clientX;
        });

        $(".vertigo-gif-image-stack").on("touchend", function (e) {
            var te = e.originalEvent.changedTouches[0].clientX;
            if (ts > te + 5) {
                swipeStack(true);
            } else if (ts < te - 5) {
                swipeStack(false);
            }
        });
    }

    function swipeStack(swipeRight) {

        if (Tivoli.vars.windowWidth < Tivoli.settings.width.small) {
            var tl = new TimelineLite();
            var imageWidth = $('.vertigo-gif-image-stack img:first-child').width();

            var direction = 2000;
            if (swipeRight) {
                direction = -2000;
            }

            TweenMax.to($('.vertigo-gif-image-stack img:first-child'), 0.5, {
                x: direction, ease: Quad.easeInOut, onComplete: function () {
                    TweenMax.to($('.vertigo-gif-image-stack img:nth-child(2)'), 1, { y: 0, scale: 1, ease: Quad.easeInOut });

                    TweenMax.to($('.vertigo-gif-image-stack img:nth-child(3)'), 1, { y: -40, scale: 0.9, ease: Quad.easeInOut });
                    TweenMax.to($('.vertigo-gif-image-stack img:nth-child(4)'), 1, {
                        y: -80, scale: 0.8, ease: Quad.easeInOut, onComplete: function () {

                            var element = $('.vertigo-gif-image-stack img:first-child');
                            var imgSource = $(element).attr('src');
                            element.remove();
                            TweenMax.set($('.vertigo-gif-image-stack img:first-child'), { x: 0, y: 0 });
                            $(".vertigo-gif-image-stack img").last().after("<img src='" + imgSource + "' />");
                            TweenMax.to($('.vertigo-gif-image-stack img:nth-child(4)'), 1, { ease: Quad.easeInOut });
                        }
                    });
                }
            });
        }
        else {
            if (startImage == "") {
                startImage = $('.vertigo-gif-image-stack img:nth-child(1)');
            }
            var image1 = startImage,
                image2 = getNextImage(image1),
                image3 = getNextImage(image2),
                image4 = getNextImage(image3),
                image5 = getNextImage(image4);

            if (swipeRight) {
                TweenMax.to($(image1), 1, { x: -350, scale: 0.7, zIndex: 6, ease: Quad.easeInOut });
                TweenMax.to($(image2), 1, { x: -50, scale: 0.7, zIndex: 8, ease: Quad.easeInOut });
                TweenMax.to($(image3), 1, { x: -100, scale: 0.8, zIndex: 9, ease: Quad.easeInOut });

                TweenMax.to($(image4), 0.3, {
                    x: -800, scale: 1, zIndex: 10, ease: Quad.easeInOut, onComplete: function () {
                        TweenMax.to($(image4), 0.5, { x: -200, scale: 1, ease: Quad.easeInOut });
                    }
                });
                TweenMax.to($(image5), 1, { x: -300, scale: 0.8, zIndex: 7, ease: Quad.easeInOut });
                startImage = image2;

            } else {
                TweenMax.to($(image5), 1, { x: -50, scale: 0.7, zIndex: 6, ease: Quad.easeInOut });
                TweenMax.to($(image4), 1, { x: -350, scale: 0.7, zIndex: 8, ease: Quad.easeInOut });
                TweenMax.to($(image3), 1, { x: -300, scale: 0.8, zIndex: 9, ease: Quad.easeInOut });

                TweenMax.to($(image2), 0.3, {
                    x: 800, scale: 1, zIndex: 10, ease: Quad.easeInOut, onComplete: function () {
                        TweenMax.to($(image2), 0.5, { x: -200, scale: 1, ease: Quad.easeInOut });
                    }
                });
                TweenMax.to($(image1), 1, { x: -100, scale: 0.8, zIndex: 7, ease: Quad.easeInOut });
                startImage = image5;
            }
        }
    }

    function getNextImage(image) {
        if ($(image).next('img').length > 0) {
            return $(image).next();
        }
        return $('.vertigo-gif-image-stack img:first-child');
    }

    function enableErorrMsg() {
        $('.vertigo-gif-image-section').hide();
        $('.vertigo-gif-image-error').addClass('vertigo-gif-image-error--enabled')
    }


    $(document).ready(function () {
        init();
    });

    return {

    };
})();