var Tivoli = $.extend(Tivoli, {
    Location: {}
});

Tivoli.Location = $.extend(Tivoli.Location, {

    Map: function (options) {
        var self = this;
        var map = {};
        var overlay = {};
        var cnt = null;

        var defaultOptions = {
           zoom: 19,
            minZoom: 19,
            maxZoom: 20,
            center: null,
            streetViewControl: false,
            panControl: false,
            zoomControl: false,
            mapTypeControl: false
        };

        this.options = $.extend(defaultOptions, options);

        this.init = function (container) {
            cnt = container;
            map = new google.maps.Map($(cnt)[0], self.options);
            //var map = new google.maps.Map($(cnt), self.options);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(self.options.mapTextLatitude, self.options.mapTextLongitude),
                cursor: 'default'
            });
            marker.setIcon(self.options.markerIconUrl);
            marker.setMap(map);

            // Listen for the dragend event
            google.maps.event.addListener(map, 'dragend', function () {
                setMapPostionCenter();
            });

            overlay = new google.maps.GroundOverlay(self.options.tivoliMapUrl, self.options.overlayBounds);
            overlay.setMap(map);

            /* constructor */
            function imageOverlay() { }

            function setMapPostionCenter() {
                if (self.options.bounds.contains(map.getCenter())) return;

                // Out of bounds - Move the map back within the bounds

                var c = map.getCenter(),
                    x = c.lng(),
                    y = c.lat(),
                    maxX = self.options.bounds.getNorthEast().lng(),
                    maxY = self.options.bounds.getNorthEast().lat(),
                    minX = self.options.bounds.getSouthWest().lng(),
                    minY = self.options.bounds.getSouthWest().lat();

                if (x < minX) x = minX;
                if (x > maxX) x = maxX;
                if (y < minY) y = minY;
                if (y > maxY) y = maxY;

                map.setCenter(new google.maps.LatLng(y, x));
            }
            

            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            imageOverlay.prototype.onAdd = function () {

                var div = document.createElement('div');
                div.style.border = 'none';
                div.style.borderWidth = '0px';
                div.style.position = 'absolute';

                // Create the img element and attach it to the div.
                var img = document.createElement('img');
                img.src = this.image_;
                img.style.width = '100%';
                img.style.height = '100%';
                div.appendChild(img);

                this.div_ = div;

                // Add the element to the "overlayImage" pane.
                var panes = this.getPanes();
                panes.mapPane.appendChild(this.div_);
            };

            imageOverlay.prototype.draw = function () {

                // We use the south-west and north-east
                // coordinates of the overlay to peg it to the correct position and size.
                // To do this, we need to retrieve the projection from the overlay.
                var overlayProjection = this.getProjection();

                // Retrieve the south-west and north-east coordinates of this overlay
                // in LatLngs and convert them to pixel coordinates.
                // We'll use these coordinates to resize the div.
                var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
                var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

                // Resize the image's div to fit the indicated dimensions.
                var div = this.div_;
                div.style.left = sw.x + 'px';
                div.style.top = ne.y + 'px';
                div.style.width = (ne.x - sw.x) + 'px';
                div.style.height = (sw.y - ne.y) + 'px';
            };

            imageOverlay.prototype.onRemove = function () {
                this.div_.parentNode.removeChild(this.div_);
            };

            // [START region_hideshow]
            // Set the visibility to 'hidden' or 'visible'.
            imageOverlay.prototype.hide = function () {
                if (this.div_) {
                    // The visibility property must be a string enclosed in quotes.
                    this.div_.style.visibility = 'hidden';
                }
            };

            imageOverlay.prototype.show = function () {
                if (this.div_) {
                    this.div_.style.visibility = 'visible';
                }
            };

            imageOverlay.prototype.toggle = function () {
                if (this.div_) {
                    if (this.div_.style.visibility == 'hidden') {
                        this.show();
                    } else {
                        this.hide();
                    }
                }
            };

            // Detach the map from the DOM via toggleDOM().
            // Note that if we later reattach the map, it will be visible again,
            // because the containing <div> is recreated in the overlay's onAdd() method.
            imageOverlay.prototype.toggleDOM = function () {
                if (map.getMap()) {
                    // Note: setMap(null) calls OverlayView.onRemove()
                    setMap(null);
                } else {
                    setMap(this.map_);
                }
            };

            setMapPostionCenter();
        };
    },
});
