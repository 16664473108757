var Tivoli = Tivoli || {};

Tivoli.RestrictionModal = (function () {

	var vars = {
		cookie: Tivoli.Cookies
	};

	var $restrictionModal = $('.restriction-modal'),
		$container= $('#site-container'),
		$html = $('html'),
		$buttonDecline = $('.js-restriction-decline'),
		$buttonApprove = $('.js-restriction-approve');

	var disclaimer = vars.cookie.get("cookieDisclaimer");
	var ageRestrictionApproval = vars.cookie.get("ageRestrictionApproval");

	function init () {
		if(ageRestrictionApproval === "true" || $restrictionModal.length <= 0) {
			return
		}
		outputModal();

	};

	function outputModal() {
		$restrictionModal.show();
		$container.after($restrictionModal);
		$html.addClass('overlay-active');
		$container.addClass('overlay-blur');


		$buttonDecline.on('click', function() {
			disableCookieDisclaimer();
		});

		$buttonApprove.on('click', function() {
			setRestrictionCookie();
			closeRescrictionModal();
		});
	}

	function closeRescrictionModal() {
		$html.removeClass('overlay-active');
		$container.removeClass('overlay-blur');
		$restrictionModal.removeClass('restriction-modal--active');

		setRestrictionCookie();
		disableCookieDisclaimer();

		setTimeout(function() {
			$restrictionModal.remove();
		}, 500);
	}

	function setRestrictionCookie() {
		vars.cookie.set("ageRestrictionApproval", "true", 500);
	}

	function disableCookieDisclaimer() {
		vars.cookie.set("cookieDisclaimer", "true", 500);
	}

	$(document).ready(function () {
		init();
	});
})();