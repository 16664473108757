var Tivoli = Tivoli || {};

Tivoli.parallaxtop = function () {

	var settings = {
		$wrapper    	:   '.js-parallax',
		$content    	:   '.hero-media',
		$pattern	 	:	'.hero-pattern',
		$background 	:   '.itemlist-section',
		$promotion		:	'.promotion-section',
		$contentSibling : 	'.hero-section + section'
	};

	var $html =				$('html'),
		$wrapper =			$(settings.$wrapper),
		$content =			$wrapper.find(settings.$content),
		$pattern =			$wrapper.find(settings.$pattern),
		$teasers = 			$wrapper.siblings(settings.$background),
		$promotion =		$wrapper.siblings(settings.$promotion),
		$window =			$(window),
		oldScrollPos = 		0,
		menuHeight = 		0,
		windowHeight =		0,
		windowPosition =	0,
		scrollTop = 		0,
		prevScrollTop;

	

	function init() {

		if($promotion.length !== 0 && $teasers.length !== 0) {
			setBackgroundColors();
		}

		// NO PARALLAX FOR BROWSERS NOT SUPPORTING ANIMATION FRAME (performance issue)
		if($wrapper.length === 0 || !Modernizr.prefixed('requestAnimationFrame', window)) {
			return;
		}

	    if (!Tivoli.vars.isAnyTouchDevice) {
			animateElements();
		}

		$window.on('scroll', function() {
		    if ($wrapper.length !== 0 && Modernizr.prefixed('requestAnimationFrame', window) && !$html.hasClass('IOS7')) {
	            setupValues();
	            updatePage();
	        }
	    });
	}

	/*function mediaPlay(play) {
	    if ($content.find('video').length) {
	        var video = $content.find('video');
	        if (video.length > 0) {
	            if (!play) {
	                video.get(0).pause();
	            } else {
	                video.get(0).play();
	            }
	        }
	    } else if ($content.find('#yt-player').length) {
            if (!play) {
	            Tivoli.Youtube.pause();
            } else if (!Tivoli.Youtube.playerIsActive()) {
                 Tivoli.Youtube.init();
            } else {
	            Tivoli.Youtube.play();
            }
	    }
	}*/

	function setupValues() {
		scrollTop = $window.scrollTop();
		windowHeight = $wrapper.height();
		windowPosition = $wrapper.offset().top;
	}

	function updatePage() {

		//if(Modernizr.prefixed('requestAnimationFrame', window)) {
			window.requestAnimationFrame(function () {
				setAllElements();
			});
		/*} else {
			setAllElements();
		}*/
	}

	function setBackgroundColors() {
		var promotionBgColor = $promotion.css('backgroundColor');
		$teasers.css('background', promotionBgColor);
	}

	function setAllElements() {
		if(setScrollTops() !== prevScrollTop) {
			setScrollTops();
			animateElements();

			prevScrollTop = $window.scrollTop();
		}
	}

	function setScrollTops() {
		scrollTop = $window.scrollTop();
		return scrollTop;
	}

	function animateElements() {

		if(scrollTop <= (windowHeight)) {

			var scrollAnim =+ (scrollTop/1.5);
			oldScrollPos = scrollAnim;

			$content.css({
			  position: 'absolute',
			  //transform: 'translate3d(0,' + (menuHeight + -(scrollAnim/4).toFixed(2)) + 'px ,0)'
				transform: 'translateY(' + (menuHeight + -(scrollAnim/4).toFixed(2)) + 'px)'
			});
			if($pattern.length > 0) {
			  $pattern.css({
				position: 'absolute',
				  ///transform: 'translate3d(0,' + (menuHeight + -scrollAnim) + 'px ,0)'
				  transform: 'translateY(' + (menuHeight + -scrollAnim) + 'px)'
			  });
			}

		  } else {
			  /*if(!Tivoli.isBrowser.IE()) {
				$pattern.css({
				  position: 'relative'
				});
			  }*/
			  $content.css({
				position: 'absolute'
			  });
		  }

		if (scrollTop < 0) {
			var scaleRatio = (1 + ($window.scrollTop()/600) * (-1)).toFixed(3);
			// SCALE EFFECT FOR "< 0"-scrolling
			$content.css({
			  transform: 'scale( ' + scaleRatio + ')',
			});
			$pattern.css({
			  transform: 'translateY( ' + $window.scrollTop()*2 + 'px)'
			});
			// TOP GRADIENT EFFECT
			$('.gradient-hero-element').height(-(scrollAnim*1) + 'px');
		}
	}
    
	$(document).ready(function () {
	    init();
	});
}();