var Tivoli = Tivoli || {};

Tivoli.promotion = (function () {
    "use strict";
    
	function init() {
		$(".promotion-section .buttons").has('a[href*="apps.apple.com"]').has('a[href*="play.google.com"]').each(function(_, x){
				$(x).find('a').each(function(_, a){  
						$(a).click(function(){
							push($(a).find('span').text())
						})
					}
				)
			}
		)

		function push(label) {
			window.digitalData = window.digitalData || []

			window.digitalData.push({
				"event": "Download app click",
				"interaction": {
				  "action": "Click", 
				  "label": label,
				  "component": "Download item",
				  "location": "body",
				  "type": "Download interaction"
			  	}
			})
		}
	}

	$(document).ready(function() {
		init()
	})

    return {
        init: init
    }
})()
