var Tivoli = Tivoli || {};
Tivoli.Shop = Tivoli.Shop || {};

Tivoli.Shop.Tracking = function () {

    function init() {
        loadConsent();
        setTimeout(function () { addCrossDomainTracking(true); }, 500)
    };

    function loadConsent() {
        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams) {
            var myParam = urlParams.get('cookiesOff');

            if (document.referrer === "app" || myParam) {
                CookieInformation.declineAllCategories();
            }
        }

        window.addEventListener('CookieInformationConsentGiven', function (event) {
            if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
                Tivoli.Cookies.set('sat_track', true, 24);
            }
            else {
                Tivoli.Cookies.set('sat_track', false, 24);
            }
            if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                Tivoli.Cookies.set('sat_track_marketing', true, 24);

                // Reinstate tracking if cookies accepted
                loadAllTrackingProducts();
                promotionalTracking();
                promotionalClickTracking();
                heroClickTracking();
            }
            else {
                Tivoli.Cookies.set('sat_track_marketing', false, 24);
            }
        }, false);

    }

    function heroClickTracking() {
        try {
            $('.hero-content a, .js-promotional-tracking-click').each(function () {
                var ariaLabel = $(this).attr('aria-label');
                var textLabel = $(this).text();
                var trackingDataAttribute = $(this).attr('data-tracking-clicktext');

                if (typeof (trackingDataAttribute) !== 'undefined' && trackingDataAttribute.length > 0) {
                    $(this).on('click', function () {
                        var event = {
                            event: 'banner_cta_click',
                            cta_button: trackingDataAttribute
                        };
                        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                        dataLayer.push(event);
                        console.log(event);
                    })
                }
                else if (typeof (ariaLabel) !== 'undefined' && ariaLabel.length > 0) {
                    $(this).on('click', function () {
                        var event = {
                            event: 'banner_cta_click',
                            cta_button: ariaLabel
                        };
                        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                        dataLayer.push(event);
                        console.log(event);
                    })
                } else if (typeof (textLabel) !== 'undefined' && textLabel.length > 0) {
                    $(this).on('click', function () {
                        var event = {
                            event: 'banner_cta_click',
                            cta_button: textLabel
                        };
                        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                        dataLayer.push(event);
                        console.log(event);
                    })
                }

            })
        } catch (err) {
            console.log(err)
        }
    }

    function addCrossDomainTracking(initalRun) {
        initalRun = typeof initalRun !== 'undefined' ? initalRun : false;
        try {
            if (typeof (window) != "undefined" && typeof (window.Visitor) != "undefined" && typeof (adobe_mcoid) != "undefined") {
                var visitor = window.Visitor.getInstance(adobe_mcoid, { overwriteCrossDomainMCIDAndAID: true });
                for (var i = 0; i < adobe_domains.length; i++) {
                    var domain = adobe_domains[i]
                    var outboudLinks = jQuery('a[href*="' + domain + '"]').not('[href*="adobe_mc="]');
                    for (var j = 0; j < outboudLinks.length; j++) {
                        var link = outboudLinks[j];
                        var currentURL = jQuery(link).attr("href")
                        jQuery(link).attr("href", visitor.appendVisitorIDsTo(currentURL))
                    }
                }
            } else if (initalRun) {
                setTimeout(function () { addCrossDomainTracking(false); }, 500)
            }
        } catch (err) {
        }
    }

    function promotionalTracking() {
        $('.js-promotional-tracking').appear();
        $('.js-promotional-tracking').on('appear', function () {
            dataLayer.push({
                event: 'promotionViewTivoliDk',
                ecommerce: {
                    promoView: {
                        promotions: [{
                            id: $(this).data('tracking-id'),
                            name: $(this).data('tracking-name'),
                            creative: $(this).data('tracking-creative'),
                            position: window.location.href
                        }]
                    }
                }
            });
            digitalDataPromotionInview($(this).data('tracking-creative'));
            $(this).off();
        });
        $.force_appear();
    };

    function promotionalClickTracking() {
        $('.js-promotional-tracking-click').on('click', function () {
            var $trackingElm = $(this).parents('.js-promotional-tracking');
            dataLayer.push({
                event: 'promotionClickTivoliDk',
                ecommerce: {
                    promoClick: {
                        promotions: [{
                            id: $trackingElm.data('tracking-id'),
                            name: $trackingElm.data('tracking-name'),
                            creative: $trackingElm.data('tracking-creative') + ":" + $(this).data('tracking-clicktext'),
                            position: window.location.href
                        }]
                    }
                }
            });
            digitalDataPromotionClick($trackingElm.data('tracking-creative'));
        });
    };

    function loadAllTrackingProducts() {
        var gaProducts = [];
        var detailViewPage = 'productoverview';


        $(".js-product-tracking:not(.js-is-product-group)").each(function () {
            var product = $(this);

            var gaProduct = {
                'name': product.data('ga-product-name'),
                'id': product.data('ga-product-id'),
                'price': product.data('ga-product-price'),
                'category': product.data('ga-product-category')
            }

            if (product.data('ga-detail-view') === 'productpage') {
                detailViewPage = 'productPage';
            }

            gaProducts.push(gaProduct);
        });

        if (gaProducts.length > 0) {
            handleGoogleAnalyticsDetailViewEvent(gaProducts, detailViewPage);
        }
    };

    function handleGoogleAnalyticsDetailViewEvent(products, detailView) {
        dataLayer.push({
            event: 'detailViewTivoliDk',
            ecommerce: {
                detail: {
                    actionField: {
                        list: detailView, // -> ’produktoversigt’ eller ’produktside’
                        dimension4: 'Tivoli.dk' // fast værdi -> skelne mellem tivoli.dk og billetlugen etc.
                    },
                    'products': products
                }
            }
        });
    };

    function digitalDataSearch(results, query) {
        initDigitalDataArray();

        digitalData.push({
            "event": "Onsite Search",
            "search": {
                "onsiteSearchTerm": query, // i.e. "tivolikort" 
                "onsiteSearchResults": results // Amount of search results for given search query    
            },
        });
    };

    function digitalDataPromotionInview(name) {
        initDigitalDataArray();

        digitalData.push({
            "event": "Internal Banner Impressions",
            "interaction": {
                "action": "Impressions",
                "label": name, // i.e. "Sanne - The Musical"
                "type": "Internal promotions",
            },
        });
    };

    function digitalDataPromotionClick(name) {
        initDigitalDataArray();

        digitalData.push({
            "event": "Internal Banner Click",
            "interaction": {
                "action": "Click",
                "label": name, // i.e. "Sanne - The Musical"
                "type": "Internal promotions",
            },
        });
    };

    function digitalDataProgramPrint(day, date) {
        initDigitalDataArray();

        digitalData.push({
            "event": "Program",
            "interaction": {
                "action": "Print",
                "label": day, // i.e. "Sanne - The Musical"
                "component": date, // i.e. "October 3, 2019"
                "type": "Internal promotions",
            },
        });
    };

    function digitalDataProgramClick(name, date) {
        initDigitalDataArray();

        digitalData.push({
            "event": "Program",
            "interaction": {
                "action": "Click",
                "label": name, // i.e. "Sanne - The Musical"
                "component": date, // i.e. "October 3, 2019"
                "type": "Internal promotions",
            },
        });
    };

    function initDigitalDataArray() {
        digitalData = digitalData ? digitalData : [];
    };

    function pushDigitalDataCartAdd(updates) {
        initDigitalDataArray();

        var addProducts = [];
        for (var i = 0; i < updates.length; i++) {
            var ol = updates[i]
            addProducts.push({
                "quantity": ol.quantityChange,
                "productInfo": [{
                    "productId": ol.productId, // The ID attached to the product 
                    "productName": ol.productName, // The product friendly name
                    "productCategory": ol.productCategory || "",
                    "productType": ol.productType || "", //Add-on, Upsale
                    "sku": ol.sku || "", // The SKU 
                    "color": "", // Color might not always be set, if it isn't send it empty
                    "size": "", // Size might not always be set, if it isn't send it empty
                    "variant": ol.variant || "", // Variant might not always be set, if it isn't send it empty
                }]
            });
        }
        digitalData.push({
            "event": "scAdd",
            "product": addProducts
        });
    };

    function pushDigitalDataCartRemove(updates) {
        initDigitalDataArray();

        var removeProducts = [];
        for (var i = 0; i < updates.length; i++) {
            var ol = updates[i]
            removeProducts.push({
                "quantity": ol.quantityChange,
                "productInfo": [{
                    "productId": ol.productId, // The ID attached to the product 
                    "productName": ol.productName, // The product friendly name
                    "productCategory": ol.productCategory || "",
                    "productType": ol.productType || "", //Add-on, Upsale
                    "sku": ol.sku || "", // The SKU 
                    "color": "", // Color might not always be set, if it isn't send it empty
                    "size": "", // Size might not always be set, if it isn't send it empty
                    "variant": ol.variant || "", // Variant might not always be set, if it isn't send it empty
                }]
            })
        }


        digitalData.push({
            "event": "scRemove",
            "product": removeProducts
        });
    };

    function pushDigitalDataCartQuantityUpdate(updated) {
        var updatedCopy = updated == null ? [] : updatedCopy
        var added = []
        var removed = []

        for (var i = 0; i < updatedCopy.length; i++) {
            var ol = updatedCopy[i]
            if (ol.quantityChange > 0) {
                added.push(ol)
            } else {
                var rmv = ol
                rmv.quantityChange = -ol.quantityChange
                rmv.push(rmv)
            }
        }
        added.length > 0 && pushDigitalDataCartAdd(added)
        removed.length > 0 && pushDigitalDataCartRemove(removed)
    };

    $(document).ready(function () {
        init();
    });

    return {
        digitalDataSearch: digitalDataSearch,
        digitalDataProgramPrint: digitalDataProgramPrint,
        digitalDataProgramClick: digitalDataProgramClick,
        pushDigitalDataCartQuantityUpdate: pushDigitalDataCartQuantityUpdate,
        addCrossDomainTracking: addCrossDomainTracking
    };

}();