var Tivoli = Tivoli || {};
Tivoli.Campiagn = Tivoli.Campiagn || {};

Tivoli.Campiagn.Halloween17 = function () {

	var $video;
	var inTheHauntedLoop = true;
	var $stopButton;
	var hauntedLoopTime = 20;
	var endLoopTime = 28;
	var startOfTheEnd = 22;

	function init() {
		$video = $("#halloween17-video");
		$stopButton = $("[data-stop-haunting-me]");

		if ($video.length <= 0) {
			return;
		}
		videoPreload($video, $stopButton);
		stopHauntingMe($stopButton);
	}

	function videoPreload($video) {

		handleHorrorVideo($video);
		$stopButton.removeClass("hidden");

		// $video.on("canplaythrough", function() {
		// 	handleHorrorVideo($video);
		// 	$stopButton.removeClass("hidden");
		// 	return;
		// }, false);
		//
		// if ($video[0].readyState > 3) {
		// 	handleHorrorVideo($video);
		// 	$stopButton.removeClass("hidden");
		// }
	}

	function stopHauntingMe($button) {
		$button.on("click", function(e) {
			e.preventDefault();
			inTheHauntedLoop = false;
			restartVideoTo($video, startOfTheEnd);
			$(this).addClass("button--halloween17--active").attr("disabled");
			handleContentEffect($button);

			// END RE-TARGETING THING
			if (window.adf) {
				window.adf.ClickTrack('', 669367, 'Unfollow knap - Børnehjemmet', {})
			}
		})
	}

	function handleContentEffect($button) {
		setTimeout(function() {
			$button.addClass("button--halloween17--done");
			$button.closest("div").css({"opacity": 0.3});

			$video[0].ontimeupdate = function() {
				var currentVideoTime = $video[0].currentTime;
				if (currentVideoTime >= $video[0].duration - 3) {
					$button.closest("div").addClass("inverted").css({"opacity": 1});
					return;
				}
			}
		}, 3000)
	}

	function handleHorrorVideo($video) {

		$video.removeClass("video-not-ready");
		$video[0].play();

		$video[0].ontimeupdate = function () {
			var currentVideoTime = $video[0].currentTime;

			if (inTheHauntedLoop) {
				if (currentVideoTime >= hauntedLoopTime) {
					restartVideoTo($video, 0);
				}
			} else if (currentVideoTime >= $video[0].duration) {
					restartVideoTo($video, endLoopTime);
					$video[0].play();
			}
		}
	}

	function restartVideoTo($video, time) {
		$video[0].currentTime = time;
	}

	function checkCurrentTime($video) {
			return $video[0].currentTime;
	}

	$(document).ready(function () {
		init();
	});

}();