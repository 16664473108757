var Tivoli = Tivoli || {};

Tivoli.pageTabs = function () {

	var pageTabNav 					= $('.js-page-filter-tab-navigation'),
		tabPages 					= $('.js-tab-page'),
		selectedTabPage 			= $('.js-tab-page-selected'),
		tabPageElements				= tabPages.children('section'),
		activePageIndex,
		prevPageIndex;

	function initTabFiltering() {

	    var pageTab = pageTabNav.find('.tab-filter');
		activePageIndex = pageTabNav.find('li.js-tab-selected').index();
        
		pageTab.on('click', function(e) {
			e.preventDefault();

			var tabSection = $(this).closest('.page-filter');

			activePageIndex = $(this).index();

			if (typeof (history.pushState) !== "undefined") {
			    var obj = { Page: $(this).data("id"), Url: "?tab=" + $(this).data("id") };
			    history.pushState(obj, obj.Page, obj.Url);
			}

			if(prevPageIndex !== activePageIndex) {
				tabSection.find('.js-selected').removeClass('js-selected');
				$(this).addClass('js-selected');

				prevPageIndex = activePageIndex;
			    var newPageId = $(this).data("id");

			    changePage(newPageId, tabSection);
			    Tivoli.Filter.changeTab(newPageId);
			}
		});
	}

	function initTabs() {
		var tabs = pageTabNav.find('.filter');

		// SET ON LOAD
		pageTabNav.each(function() {
			var initialId = $(this).find('.js-selected').data('id');
			var tabSection = $(this).closest('.page-filter');

			changePage(initialId, tabSection);
		});


		// SET ON CLICK
		tabs.on('click', function(e) {

			if($(this).hasClass('tab-filter')) {
				e.preventDefault();
			}
			var tabSection = $(this).closest('.page-filter');

			tabSection.find('.js-selected').removeClass('js-selected');
			$(this).addClass('js-selected');

			var newPageId = $(this).data("id");
			var tabSection = $(this).closest('.page-filter');
			changePage(newPageId, tabSection);
		});
	}

	function hideInactivePages() {
		var inActivePages = tabPages.not(selectedTabPage);
		TweenMax.to(inActivePages.children('section'), {autoAlpha: 0});
	}

	function initTabSelection(filterTab) {
	    //var tab = Tivoli.getUrlParameterByName("tab");
	    //if (tab !== '') {
	    //    filterTab.siblings(".tab-filter").removeClass("js-selected").removeClass(".js-tab-page-selected");
	    //    filterTab.addClass("js-selected").addClass("js-tab-page-selected");
	    //}
	};

	function changePage(filterClass, tabSection) {
	    var currentElement = tabSection.find('.js-tab-page').filter("." + filterClass),
			currentPageElements = currentElement.children('section'),
			allTabPages = tabSection.find('.js-tab-page'),
			allTabPagesContent = tabSection.children('section');

		TweenMax.to(allTabPagesContent, 0.3, {autoAlpha: 0, onComplete:
			function() {
				allTabPages.removeClass('js-tab-page-selected');
				currentElement.addClass('js-tab-page-selected');
				TweenMax.staggerTo(currentPageElements, 0.3, {autoAlpha: 1}, 0.1);
			}
		});
	}

	function initTabInteractionBase(pageTabBaseFilter) {

		var fields;

		var tabBase = pageTabBaseFilter;
		var tabNavBase = pageTabBaseFilter;

		(function initFields() {
			var tabItems = tabNavBase.find("li"),
				tabContainer = tabNavBase.find("ul");

			fields = {
				tabItems: tabItems,
				tabContainer: tabContainer,
				menuOffset: tabContainer.position().left,
				totalWidth: getSize(tabContainer, tabItems),
				left: tabNavBase.find(".tab-left"),
				right: tabNavBase.find(".tab-right"),
				currentIndex: 0,
				currentOffset: 0
			};


			setMenuSize();
			initEvents();

		})();

		function setMenuSize() {
			fields.tabContainer.width(fields.totalWidth);
			checkArrows(fields.currentIndex);
		}

		function getSize(tabContainer, tabItems) {
			var total = 0;
			tabItems.each(function () {
				this.menuStartOffset = total;
				total += Math.ceil(this.offsetWidth);
			});
			return total + 5 + 2 * parseInt(tabContainer.css('padding-left'));
		}

		function initEvents() {
			fields.left.on("click", function () {
				if (canMoveLeft(fields.currentIndex)) {
					slide(fields.currentIndex - 1);
					checkArrows(fields.currentIndex);
				}
			});
			fields.right.on("click", function () {
				if (canMoveRight(fields.currentIndex)) {
					slide(fields.currentIndex + 1);
					checkArrows(fields.currentIndex);
				}
			});
			$(window).smartresize(function () {
				fields.totalWidth = getSize(fields.tabContainer, fields.tabItems);
				slide(fields.currentIndex);
				fields.menuOffset = fields.tabContainer.position().left - fields.currentOffset;
				while (!canMoveRight(fields.currentIndex - 1)) {
					slide(fields.currentIndex - 1);
				}
				checkArrows(fields.currentIndex);
			});
		}

		function canMoveRight(index) {
			if (index < 0) {
				return true;
			}

			if (fields.tabItems.length < index + 1) {
				return false;
			}
			var menuVisibleWidth = pageTabNav.width(),
				menuWidth = fields.tabContainer[0].clientWidth,
				offset = fields.tabItems[index].menuStartOffset;

			// If the last tab is visible, then stop
			if (menuVisibleWidth >= menuWidth - offset) {
				return false;
			}

			return true;
		}

		function canMoveLeft(index) {
			if (index <= 0) {
				return false;
			}
			return true;
		}

		function slide(index) {
			fields.currentOffset = fields.tabItems[index].menuStartOffset;
			fields.tabContainer.css('transform', 'translateX(-' + fields.currentOffset + 'px)');
			fields.currentIndex = index;
		}

		function checkArrows(index) {
			checkLeftArrow(index);
			checkRightArrow(index);
		}

		function checkLeftArrow(index) {
			if (canMoveLeft(index)) {
				fields.left.removeClass("hide");
			} else {
				if (!fields.left.hasClass("hide")) {
					fields.left.addClass("hide");
				}
			}
		}

		function checkRightArrow(index) {
			if (canMoveRight(index)) {
				fields.right.removeClass("hide");
			} else {
				if (!fields.right.hasClass("hide")) {
					fields.right.addClass("hide");
				}
			}
		}
	}



	function init() {
	    if (pageTabNav.length === 0) {
	        return;
	    }

		var pageFilerParentTarget = $('.page-filter-tab-navigation');

		pageFilerParentTarget.each(function() {
			initTabInteractionBase($(this));
		});

	    
		initTabFiltering();
		initTabs();
		hideInactivePages();
    }

    $(document).on('allFontsLoaded', function () {
        init();
    });

    return {
        initTabSelection: initTabSelection
    };
}();