var Tivoli = Tivoli || {};

Tivoli.promotion = (function () {
    "use strict";
    
	function init() {
		$("a[data-book-restaurant-name]").click(function() {
			push($(this).find('span').text(), $(this).attr('data-book-restaurant-name'))
		})

		function push(label, restaurantName) {
			window.digitalData = window.digitalData || []

			window.digitalData.push({
				"event": "Book table click",
				"interaction": {
				  "action": "Click", 
				  "label": label || "" ,
				  "restaurantName": restaurantName || "" ,
				  "component": "Book table item",
				  "type": "Book table interaction"
			  	}
			})
		}
	}

	$(document).ready(function() {
		init()
	})

    return {
        init: init
    }
})()
