var Tivoli = Tivoli || {};
Tivoli.ErrorPopup = (function () {
   
    function show(title, description, buttonText, buttonAction){
        removeAlert();
        $("body").append(""
        +"<div class=\"reactmodal-section\" id=\"jErrorPopup\">"
        +"    <div>"
        +"        <div class=\"Modal\" >"
        +"           <div class=\"ErrorPopup\">"
        +"              <div class=\"ErrorPopup__clickarea\">"
        +"              </div>"
        +"          </div>"
        +"          <div class=\"ErrorPopupContent\">"
        +"              <div class=\"ErrorPopupContent_frame\">"
        +"                  <div class=\"ErrorPopupStd\">"
        +"                      <button class=\"ErrorPopupCloser ErrorPopupStd__close\" onclick='Tivoli.ErrorPopup.remove()'><i class=\"icon-close\"></i></button>"
        +"                      <div class=\"ErrorPopupStd__inner\">"
        +"                          <h1>"+title+"</h1>"
        +"                          <p>"+description+"</p>"
        +"                          <form class=\"ErrorPopupStd__form\" id=\"jErrorPopupForm\">"
        +"                          <button class=\"UIButton ErrorPopupStd__btn UIButton--theme-rounded ErrorPopupStd__btn\">"
        +"                              <span class=\"UIButton__content-container\">"
        +"                                  <span class=\"UIButton__content\">"+buttonText+"</span>"
        +"                                  </span>"
        +"                              </span>"
        +"                          </button>"
        +"                          </form>"
        +"                      </div>"
        +"                  </div>"
        +"              </div>"
        +"          </div>"
        +"      </div>"
        +"  </div>"
        +"</div>"
        )
        $("#jErrorPopupForm").submit(buttonAction)
    }

    function removeAlert(){
        $("#jErrorPopup").remove()
    }
    return {
        show: show,
        remove: removeAlert
    };
})();