var Tivoli = Tivoli || {};


Tivoli.customForm = function() {
    "use strict";



    function init() {
		 $.each($('.js-custom-signup-form'), function () {
            handleCustomFormPost($(this));
        });
		
    }


	function handleCustomFormPost(formContainer) {

        var outerContainer = formContainer.parents(".js-custom-form-container");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function() {
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
				window.location.href = data.RedirectUrl;
            } else {
				 Tivoli.CircleLoader.reset(loader);
				 Tivoli.ButtonMessage.showErrorMessage(button, data.Error);
            }
        });
        
        formContainer.on("form-error", function () {
			 Tivoli.CircleLoader.reset(loader);
			 console.log('form erorr');
        });
    };
    
     $(document).ready(function () {
	    init();
	});

}();

