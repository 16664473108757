var Tivoli = Tivoli || {};

Tivoli.tooltip = function () {

    var isTouch = Modernizr.touch;

    $.fn.tooltip = function () {
        this.each(function () {

            var tooltip = $(this);
            var tooltipMessage = tooltip.data('tip');
            var tooltipIcon = '<span class="tooltip__icon"></span>';
            var tooltipMessageContainer = '<div class="tooltip__message">' + tooltipMessage + '</div>';

            // APPEND tooltip HTML
            tooltip.find('div').prepend(tooltipIcon);
            tooltip.find('div').find('.tooltip__icon').prepend(tooltipMessageContainer);


            if(!isTouch) {
                tooltip.find('.tooltip__icon').on('mouseover', function() {
                    tooltip.addClass('tooltip__message--active');
                });
                tooltip.find('.tooltip__icon').on('mouseout', function() {
                    tooltip.removeClass('tooltip__message--active');
                });

            } else {
                classToggleTouch(tooltip);
            }
        });
    };
    function classToggleTouch(tooltip) {
        //console.log(tooltip.find('.tooltip__icon'))
        tooltip.find('.tooltip__icon').on('touchend ', function() {
            if(tooltip.is('tooltip__message--active')) {
                tooltip.removeClass('tooltip__message--active');
            } else {
                tooltip.addClass('tooltip__message--active');
            }
        });
    }


    function init() {

        if($('.js-tooltip').length === 0) {
            return;
        }

        $('.js-tooltip').tooltip();
    }

    $(document).ready(function () {
        init();
    });
}();