var Tivoli = Tivoli || {};

Tivoli.Filter = (function () {
    var fields = {
        $filterContainer: $(".list-sorting"),
        $filterSelector: $("#filters"),
        ajaxFilters: $(".ajax-filter .filter-controls"),
        ajaxTabs: $(".ajax-filter .tab-filter"),
        dropDownFilters: $(".filter-search.dropdown"),
        sliderFilters: $(".filter-search.slider"),
        checkFilters: $(".filter-search.checkbox"),
        textFilters: $(".filter-search.textbox"),
        $filterResult: $(".js-active-in-filter"),
        loaded: false,
        datepickerInitialized: false
    };

    var vars = {
        cookie: Tivoli.Cookies,
        registeredFilterControls: [],
    };

    //Save data attributes to a cookie for later search
    var saveFilterSelection = function (tab) {
        var filterValue = "";
        fields.$filterSelector.find(".filter-search").each(function (index, element) {
            var $element = $(element);
            if ($element.hasClass("slider")) {
                filterValue += "$id=" + $element.data("id");
                filterValue = assignFilterValue(filterValue, element.dataset);
            }
            else if ($element.hasClass("checkbox")) {
                filterValue += "$id=" + $element.data("id");
                filterValue += ("&active:" + $element.parent().hasClass("active"));
            }
            else if ($element.hasClass("dropdown")) {
                filterValue += "$id=" + $element.data("id");
                filterValue += "&value:" + $element.val();
            }
        });
        if (tab) {
            filterValue += "$id=" + tab.data("id");
            filterValue += "&value:" + tab.val();
        };
        vars.cookie.set(fields.$filterSelector.data("cookie"), filterValue, 1);
        return;
    };

    // Build cookie string value 
    var assignFilterValue = function (value, objectData) {
        for (var p in objectData) {
            if (objectData.hasOwnProperty(p)) {
                value += ("&" + p + ":" + objectData[p]);
            }
        }
        return value;
    };

    //Retrieve values from query string
    var getFilterSelectionFromQueryString = function () {
       if(fields.loaded) {
           return 
       }
       var query = location.search;
       if(query == "") {
           return;
       }

       fields.loaded = true;
       var search = location.search.split('search=').splice(1).join('').split('&')[0]
       var elements = search.split("--");
       vars.registeredFilterControls = []

       $.each(elements, function (index, element) {
            if (!element == "") {
                var parameters = element.split(",");
                var id = parameters[0];
                if (id !== "") {
                    var filterElement = fields.ajaxFilters.find("[data-id=" + id + "]");
                    vars.registeredFilterControls.push(filterElement);
                    initFilterSelection(filterElement, parameters[0], parameters[1]);
                }
            }
        });
        refreshParameterControls();
        return;
    }

    //Retrieve cookie values
    var getFilterSelection = function () {
        if (fields.loaded) {
            return;
        }
        fields.loaded = true;
        var tivoliRideFilterValues = vars.cookie.get(fields.$filterSelector.data("cookie"));
        var elements = tivoliRideFilterValues.split("$id=");
        vars.registeredFilterControls = [];
        $.each(elements, function (index, element) {
            if (!element == "") {
                var parameters = element.split("&");
                var id = parameters[0];
                if (id !== "") {
                    var filterElement = fields.$filterContainer.find("[data-id=" + id + "]");
                    vars.registeredFilterControls.push(filterElement);

                    for (var i = 0; i < parameters.length; i++) {
                        initFilterSelection(filterElement, parameters[i].split(":")[0], parameters[i].split(":")[1]);
                    }
                }
            }
        });
        refreshParameterControls();
        return;
    };

    //Attach dataattributes to the element
    var initFilterSelection = function (element, attribute, value) {
        var $element = $(element);
        if ($element.is(':checkbox') && value === "true") {
            $element.parent().addClass(attribute);
            $element.prop('checked', true);
        } else if ($element.hasClass("slider")) {
            fields.$filterSelector.find(element).attr("data-" + attribute, value);
        } else if ($element.hasClass("dropdown")) {
            fields.$filterSelector.find(element).val(value).selectric("refresh");
            fields.$filterContainer.find("#" + $element.data("id")).each(function (index, filter) {
                if ($(filter).length) {
                    $(filter).val(value).selectric('refresh');
                }

            });
        } else if ($element.hasClass("tab-filter")) {
            Tivoli.pageTabs.initTabSelection($element);
        }
        return;
    };

    // Set paramter controls to latest position
    var refreshParameterControls = function() {
        $.each(vars.registeredFilterControls, function (index, e) {
            var element = $(e);
            if (element.hasClass("slider")) {
                var dataSelectedMax = parseInt(element.attr('data-selectedmax')) >= 0 ? parseInt(element.attr('data-selectedmax')) : 1000;
                var dataSelectedMin = parseInt(element.attr('data-selectedmin')) >= 0 ? parseInt(element.attr('data-selectedmin')) : 0;
                element.val([dataSelectedMin, dataSelectedMax]);
            }
        });

        return;
    };

    var clearFilters = function () {
        fields.sliderFilters.each(function (index, e) {
            var element = $(e);
            var dataSelectedMax = parseInt(element.data('maxval'));
            var dataSelectedMin = parseInt(element.data('minval'));

            element.data("selectedmax", dataSelectedMax);
            element.data("selectedmin", dataSelectedMin);

            element.val([dataSelectedMin, dataSelectedMax]);
        });

        fields.checkFilters.prop("checked", false);
        fields.dropDownFilters.val("-").selectric('refresh');
        fields.textFilters.val("");
        vars.cookie.set(fields.$filterSelector.data("cookie"), '', 1);
    };

    var ajaxMethods = {
        initAjaxFilters: function() {
            if (fields.ajaxFilters.length === 0) {
                return;
            }

            getFilterSelectionFromQueryString();
            getFilterSelection();
            
            var section = fields.ajaxFilters.parents("section");
            fields.ajaxContent = section.find(".ajax-content");
            fields.ajaxFilters.on('change', function () {
                var form = $(this);
                var name = form.parents(".js-tab-page").attr("id");
                var serializedForm = ajaxMethods.serialize(form, name);
                ajaxMethods.postOnFilterChange(serializedForm);

                saveFilterSelection();
            });
            fields.ajaxFilters.each(function () {
                var form = $(this);
                if (form.hasClass("is-selected")) {
                    var name = form.parents(".js-tab-page").attr("id");
                    var serializedForm = ajaxMethods.serialize(form, name);
                    var queryFromDate = (location.search.split("fromdate" + '=')[1] || '').split('&')[0];

                    //If parameter fromDate is set, do not init search
                    if(queryFromDate == '') {
                        ajaxMethods.postOnFilterChange(serializedForm);
                    } 
                    
                }
            });
        },
        updateFilterResult: function (result) {
            fields.$filterResult.each(function () {
                $(this).html($(result).find(".js-filter-result").length);
            });
        },
        changeTab: function (newPageId) {
            if (fields.ajaxTabs.length === 0) {
                return;
            }
            var form = $("#" + newPageId + " .filter-controls");
            var serializedForm = ajaxMethods.serialize(form, newPageId);
            ajaxMethods.postOnFilterChange(serializedForm);

            saveFilterSelection();
        },
        changeContent : function(content) {
            fields.ajaxContent.empty();
            fields.ajaxContent.append(content);
            ajaxMethods.updateFilterResult(content);

            fields.ajaxContent.find(".filter-no-results a").on("click", function (e) {
                e.preventDefault();
                clearFilters();

                var activeTabName = fields.ajaxTabs.filter(".js-selected").data("id");
                var form = ajaxMethods.currentActiveForm(activeTabName);
                var serializedForm = ajaxMethods.serialize(form, activeTabName);
                ajaxMethods.postOnFilterChange(serializedForm);
            });
            fields.ajaxContent.find(".extended-search").on("click", function (e) {
                e.preventDefault();
                var form = ajaxMethods.currentActiveForm();
                var serializedForm = ajaxMethods.serialize(form);
                if ($(this).data("query-number") != "") {
                    ajaxMethods.postOnFilterChange(serializedForm, { Extended: true, CurrentCount: $(this).data("query-number") });
                } else {
                    ajaxMethods.postOnFilterChange(serializedForm, { Extended: true });
                }
                
            });
            

            Tivoli.LazyLoading.LazyLoad(fields.ajaxContent.find("img.lazy"), fields.ajaxContent.find("[data-bg].lazy"));
            Tivoli.accordion.init();
        },
        currentActiveForm: function (activeTabName) {
            if (activeTabName) {
                return $("#" + activeTabName + " .filter-controls");
            } else {
                return fields.ajaxFilters.filter(".is-selected");
            }
        },
        serialize : function (form, tabName) {
            var filters = {
                SlideFilters: [],
                CheckFilters: [],
                DropDownFilters: [],
                TextFilters: [],
            };

            filters.TabName = tabName;

            form.find("input[type=hidden]").each(function() {
                var input = $(this);
                filters[input.attr("name")] = input.val();
            });
            form.find("input[type=checkbox]").each(function () {
                var input = $(this);
                if (input.prop('checked')) {
                    filters.CheckFilters.push({
                        FilterId: input.attr("name"),
                        Value: true
                    });
                }
            });
            form.find(".filter-search.dropdown").each(function () {
                var input = $(this);
                var value = input.val();
                if (value && value.length > 0) {
                    filters.DropDownFilters.push({
                        FilterId: input.attr("name"),
                        Value: value
                    });
                }
            });
            form.find(".filter-search.slider").each(function () {
                var input = $(this);
                filters.SlideFilters.push({
                    FilterId: input.attr("name"),
                    ValueMin: input.data("selectedmin"),
                    ValueMax: input.data("selectedmax")
                });
            });
            form.find(".filter-search.daterange").each(function () {
                var input = $(this);

                if (fields.datepickerInitialized || input.siblings(".daterangepicker").length) {
                    fields.datepickerInitialized = true;

                    // If local time is 0:00, then result will be UTC 0:00
                    function dateToUtcString(date) {
                        var localDateChangedToUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
                        return localDateChangedToUtc.toISOString();
                    }

                    var range = input.daterangepicker("getRange");
                    if (range) {
                        var from = dateToUtcString(range.start);
                        range.end.setDate(range.end.getDate() + 1);
                        var to = dateToUtcString(range.end);

                        if (from && to) {
                            filters.Period = {
                                StartDate: from,
                                EndDate: to
                            };
                        }
                    }
                }
            });
            form.find(".filter-search.textbox").each(function () {
                var input = $(this);
                var value = input.val();
                if (value && value.length > 0) {
                    filters.TextFilters.push({
                        FilterId: input.attr("name"),
                        Value: value
                    });
                }
            });

            return filters;
        },
        postOnFilterChange: function (serializedForm, settings) {
            ajaxMethods.startSearch();
            
            $.extend(serializedForm, settings);
            $.ajax({
                type: "POST",
                url: "?json=1",
                data: JSON.stringify(serializedForm),
                contentType: 'application/json; charset=utf-8',
                success: function (response) {
                    ajaxMethods.changeContent(response);
                    ajaxMethods.endSearch();
                },
                error: function (a, b, c) {
                    ajaxMethods.showError();
                    ajaxMethods.endSearch();
                },
                dataType: "html"
            });
        },
        startSearch: function() {
            // Show loader.
            fields.ajaxContent.addClass('loader-running');
        },
        endSearch: function() {
            // Hide loader.
            fields.ajaxContent.removeClass('loader-running');

            // Emit completion event
            emitFilterSearchFinishedEvent();

        },
        showError: function () {
            // Tell the user, that an error occured?
        }
    };

    ajaxMethods.initAjaxFilters();

    function emitFilterSearchFinishedEvent() {
        $(document).trigger('filterSearchComplete');
    }

    return {
        getFilterSelection: getFilterSelection,
        saveFilterSelection: saveFilterSelection,
        clearFilters: clearFilters,
        updateFilterResult: ajaxMethods.updateFilterResult,
        changeTab: ajaxMethods.changeTab
    };
})();