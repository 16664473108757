var Tivoli = Tivoli || {};

Tivoli.contextNavigation = function () {
	var $html = $('html'),
		$siteContainer = $('#site-container ');
		$contextNavTrigger = $('.js-context-nav-trigger'),
		$contextNav = $('.js-context-nav');

 	var navWidth = Tivoli.settings.nav.width;
	var mqSmall = Tivoli.settings.width.small;

	var slideOutRightClass = 'slideout-right-is-active',
		menuIsActiveClass = 'menu-is-active';

	function init() {
		if ($contextNavTrigger.length <= 0) {
			return;
		}
		triggerContextNav();
		watchForMenuChanges();

		// OPEN NAV ON LOAD (if not small)
		if($(window).width() > mqSmall) {
			openContextNav();
			$contextNavTrigger.addClass('is-active')
		}
	}

	function watchForMenuChanges() {

	}

	function triggerContextNav() {
		$contextNavTrigger.on('click', function(e) {
			e.preventDefault();
			toggleContextNav();
			$(this).toggleClass('is-active')
		});
	}

	function toggleContextNav() {
		if($contextNav.hasClass('is-active')) {
			closeContextNav();
		} else {
			openContextNav();
		}
	}

	function closeContextNav() {
		$contextNav.removeClass('is-active'); //.attr('style', '');
		$html.removeClass('context-nav-is-active');
		$siteContainer.attr('style', '');
	}

	function openContextNav() {
		var contextNavHeight = $contextNav.find('section').outerHeight();

		// ADD CLASS to html
		$html.addClass('context-nav-is-active');

		// ADD CLASS TO Content Nav and set HEIGHT
		$contextNav.addClass('is-active');//.css('max-height', contextNavHeight);

		// PUSH FROM TOP WITH MARGIN
		$siteContainer.css({
			'margin-top': contextNavHeight + 'px'
		});

	}

	$(document).ready(function () {
		init();
	});

}();