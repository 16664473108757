Tivoli = Tivoli || {};

Tivoli.tekEventSignUp = (function () {

    var submitPressed = false;

    function addVipRequiredCheck() {
        jQuery.validator.addMethod("viprequired", function (value, element) {
            var input = $(element);
            var parent = input.parents(".js-tek-members");
            var anySelected = false;
            parent.find(".js-tek-member").each(function() {
                if ($(this).prop("checked")) {
                    anySelected = true;
                }
            });

            return anySelected;
        });
    }

    function handleSignUpForm(formContainer) {
        if (formContainer == null) {
            return;
        }

        var maxParticipants = parseInt(formContainer.data("max-participants"));
        if (!maxParticipants) {
            maxParticipants = 0;
        }

        var warningBox = $(".js-tek-sign-up-warning");
        var warning = warningBox.find(".js-tek-sign-up-warning-text");

        var guestContainers = formContainer.find(".js-guest-input-container");
        guestContainers.find("input").addClass("ignore");

        var guestQuantityDdl = formContainer.find(".js-guest-quantity");
        var tekMemberCheckboxes = formContainer.find(".js-tek-member");

        function getParticipantsQuantity() {
            var participants = parseInt(guestQuantityDdl.val());
            if (!participants) {
                participants = 0;
            }
            tekMemberCheckboxes.each(function () {
                if ($(this).prop("checked")) {
                    participants++;
                }
            });
            return participants;
        }

        function checkMaxParticipants() {
            var participants = getParticipantsQuantity();
            if (participants > maxParticipants) {
                var message = warning.data("message");
                message = message.replace("{placeholder.participants}", participants);
                message = message.replace("{placeholder.allowedparticipants}", maxParticipants);
                warning.html(message);
                warningBox.show();
            } else {
                warningBox.hide();
            }
        }

        tekMemberCheckboxes.on("change", function () {
            checkMaxParticipants();
        });
        
        function onChange() {
            var guestQuantity = parseInt(guestQuantityDdl.val());
            guestContainers.each(function() {
                var guestContainer = $(this);
                var input = guestContainer.find("input");
                var id = parseInt(guestContainer.data("index"));
                if (id < guestQuantity) {
                    guestContainer.show();
                    input.removeClass("ignore");
                } else {
                    guestContainer.hide();
                    input.addClass("ignore");
                }
            });
            checkMaxParticipants();
        }
        
        guestQuantityDdl.on('change', onChange);
        guestQuantityDdl.selectric({
            onChange: onChange
        });

        onChange();

        addVipRequiredCheck();

        var requireVip = !!formContainer.data("requirevip");
        if (requireVip) {
            formContainer.on("add-custom-rules", function (e, data) {
                var rules = data.rules;
                var input = data.input;
                // Only put rules on first input to only get 1 error message
                if (input.hasClass("js-tek-member")) {
                    rules.viprequired = true;
                    rules.messages.viprequired = formContainer.data("requirevip-error");
                }
            });

            var memberInputs = formContainer.find(".js-tek-members");
            memberInputs.on("change", function () {
                if (submitPressed) {
                    formContainer.validate();
                    formContainer.valid();
                }
            });
        }

        formContainer.find(".js-custom-submit a").on("click", function() {
            submitPressed = true;
        });

        formContainer.on("form-request", function (e, request) {
            // Add guest names to a list in the form
            var guestNames = [];
            var tekMembers = [];
            for (var key in request.Form) {
                if (request.Form.hasOwnProperty(key)) {
                    if (key.indexOf("TekGuest") === 0) {
                        guestNames.push(request.Form[key]);
                        delete request.Form[key];
                    }
                }
            }
            tekMemberCheckboxes.each(function () {
                if ($(this).prop("checked")) {
                    tekMembers.push($(this).parent().attr('data-id'));
                }
            });
            request.Form["TekMembers"] = tekMembers;
            request.Form["GuestNames"] = guestNames;
            request.Form["GuestQuantity"] = $('.js-guest-quantity').val();
        });
    }

    function handleEdit() {
        $(".js-tek-signup-edit").on("click", function (e) {
            e.preventDefault();
            $(".js-tek-event-signup-info").hide();
            $(".js-tek-event-signup-edit").show();
        });
    }

    function init() {
        handleSignUpForm($('.js-event-sign-up-form'));
        handleEdit();
    }

    $(document).ready(init);

    return {

    };
})();