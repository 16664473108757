var Tivoli = Tivoli || {};

Tivoli.expandableList = (function () {
    var expandableLists = $(".expandable-list");
    expandableLists.each(function() {
        var expandableList = $(this);
        var rows = expandableList.find(".table-module__row.hide");
        var expandBtn = expandableList.find(".extended-search");
        var rowContainer = expandBtn.closest('.extend-search-results');

        expandBtn.on("click", function () {
            showNext10();
        });

        var showNext10 = function() {
            var indexCount = rows.length;
            if (indexCount > 9) {
                indexCount = 9;
            } else {
                rowContainer.hide();
            }

            for (var i = 0; i < indexCount; i++) {
                var row = $(rows[i]);
                row.removeClass("hide");
            }
            
            rows = expandableList.find(".table-module__row.hide");
        };
    });
})();