var Tivoli = $.extend(Tivoli, {
    Youtube: {}
});

Tivoli.YoutubePlayer = $.extend(Tivoli.Youtube, {
/*
    General youtube player handler, to start and stop a youtube video. 
    The player is initialized with a container element, where the video container is included, 
    and a option object containing the player element class, to observe from.
*/

    Player: function (options) {
        var self = this;

        var cnt = null;

        var defaultOptions = {};

        this.options = $.extend(defaultOptions, options);

        this.init = function (container) {
            cnt = container;

            cnt.on("click", self.options.playerButtonElementClass, function (e) {
                e.preventDefault();
                e.stopPropagation();
                var videoId = $(this).attr("data-videoId");

                if (videoId == "") { return; }

                self.enableYoutubeVideo($(e.target).closest(cnt).find(".video-player"), videoId);

            });

            $(document).on("click", function (e) {
                self.disableYoutubeVideo($(".video-player-expanded"));
            });

            //append youtube player iframe, and inject videoId
            this.enableYoutubeVideo = function (boxPlayer, videoId) {
                $(boxPlayer).switchClass("video-player", "video-player-expanded", 1000, "easeInOutQuad");

                $(boxPlayer).html('<iframe frameborder="0" width="100%" height="100%" src="" allowfullscreen></iframe>');
                var iframe = $(boxPlayer).find("iframe");
                $(iframe).attr("src", ('https://youtube.com/embed/{id}?rel=0&hd=1&autoplay=1&showinfo=0&controls=0').replace("{id}", videoId));
            };

            //Disable and remove all ongoing iframed videos
            this.disableYoutubeVideo = function (boxPlayer) {
                $(boxPlayer).each(function(index, element) {
                    if ($(element).length > 0) {
                        $(boxPlayer).switchClass("video-player-expanded", "video-player", 500, "easeInOutQuad", function () {
                            $(boxPlayer).find("iframe").remove();
                        });
                    }
                });
               
            };

            this.handleResponse = function (response) {
                if (response.Success) {
                    self.showSuccess(response.Message);
                } else {
                    self.showErrors(response.Message);
                }
            };

            this.showErrors = function (message) { };

            this.showWarnings = function (message) { };

            this.showSuccess = function (message) { };

        };
    },
});
