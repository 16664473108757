var Tivoli = Tivoli || {};

Tivoli.marquee = function () {

	var navWidth = 238;
	var pagePadding = 20;
	var delay = 3;
	var $menuTitleAnimArea;



	// SPECIFIC FOR MENU HEADLINES
	function marqueeInit() {
		$menuTitleAnimArea = $('.js-menu-overflow-anim');
		if ($menuTitleAnimArea.length <= 0) {
			return;
		}
		$menuTitleAnimArea.initNavigationMarquee();
	}

	$.fn.initNavigationMarquee = function() {

		this.each(function() {

			var $menuTitleAnimArea = $(this);
			var menuTitleAnimWidth;
			var	lengthOfAnimation;
			var animationTiming;

			updateVars($menuTitleAnimArea);
			TestMenuForMarquee($menuTitleAnimArea);

			function updateVars($titleItem) {
				menuTitleAnimWidth 	= menuTitleWidth($titleItem); // + padding
				lengthOfAnimation 	= -((menuTitleAnimWidth - navWidth));
				animationTiming 	= Math.ceil((menuTitleAnimWidth - navWidth)/100 * 5) <= 2 ? 2 : Math.ceil((menuTitleAnimWidth - navWidth)/100 * 5); // min 2 sec.
			}

			function menuTitleWidth($item) {
				return $item.outerWidth() + pagePadding;
			}

			function animateFrom() {
				TweenMax.to($menuTitleAnimArea, animationTiming, {x: lengthOfAnimation, delay: delay, ease: Power1.easeOut, onComplete: animateBack});
			}

			function animateBack() {
				TweenMax.to($menuTitleAnimArea, animationTiming, {x: pagePadding, delay: delay/2, ease: Power1.easeOut, onComplete: animateFrom});
			}

			function TestMenuForMarquee($itemToAnimate) {
				if(menuTitleWidth($itemToAnimate) >= navWidth) {
					$itemToAnimate.addClass('animation');
					animateFrom($itemToAnimate);
				} else {
					$itemToAnimate.addClass('no-animation');
				}
			}

		});
	};

	$.fn.destroyNavigationMarquee = function() {
		this.each(function() {
			var $menuTitleAnimArea = $(this);
			TweenMax.killTweensOf($menuTitleAnimArea);
			TweenMax.set($menuTitleAnimArea, {clearProps: "all", delay: delay/2});
		})
	};

	function resetMarquee() {
		$menuTitleAnimArea = $('.js-menu-overflow-anim');
		if ($menuTitleAnimArea.length <= 0) {
			return;
		}
		$menuTitleAnimArea.destroyNavigationMarquee();
	}

	return {
		marqueeInit: marqueeInit,
		resetMarquee: resetMarquee
	};
}();