var tivoli = tivoli || {};

Tivoli.program = (function () {

	function init() {
		$events = $('.js-filter-result');

		if($events.length === 0) {
			return;
        }
        
        $events.each(function(index) {
            var programLinks = $(this).find('a'),
                programName = $(this).data('name'),
                programDate = $(this).data('date');

            programLinks.on('click', function() {
                Tivoli.Shop.Tracking.digitalDataProgramClick(programName, programDate);
            })
        });
	}

	$(document).on('filterSearchComplete', function () {
		init();
	});
})();