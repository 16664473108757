Tivoli = Tivoli || {};

Tivoli.tekForm = (function () {

    function handleEnter(formContainer) {
        formContainer.find("input").keypress(function (e) {
            if (e.which === 13) {
                e.preventDefault();
                if (formContainer.valid()) {
                    formContainer.submit();
                }
                return false;
            }
        });
    }

    function handleBtbLogin(formContainer) {
        if (formContainer.length === 0) {
            return;
        }

        handleEnter(formContainer);

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                if (data.RedirectUrl) {
                    window.location.href = data.RedirectUrl;
                } else {
                    location.reload();
                }
            }
        });
    }

    function handleTekLogin(formContainer) {
        if (formContainer.length === 0) {
            return;
        }

        handleEnter(formContainer);

        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                if (data.RedirectUrl) {
                    window.location.href = data.RedirectUrl;
                } else {
                    location.reload();
                }
            }
        });
    }

    function init() {
        handleBtbLogin($('.js-btb-login-form'));
        handleTekLogin($('.js-tek-login-form'));
        handlePassImage();
        handleDeleteImage();

    }

    function handlePassImage() {
        var button = $('.js-tek-image-form').find('.js-custom-submit');
        $(button).addClass("disabled");
        var fileInput = $('.js-tek-image-form').find("input[type=file]");
        fileInput.on("file-data-changed", function (e, data) {
            $('.js-tek-image-form').find(".js-tek-member-image").get(0).src = data;
            $(button).removeClass("disabled");
            $('.js-tek-delete-image').show();
        });
    }
    function handleDeleteImage() {
        var button = $('.js-tek-image-form').find('.js-custom-submit');

        $('.js-tek-delete-image').on('click', function(e) {
            e.preventDefault();
            
            $(button).removeClass("disabled");
            var dummySrc = $(this).attr('data-dummy-image');
            $('.js-tek-image-form').find(".js-tek-member-image").get(0).src = dummySrc;
            $(this).hide();
        });
    }

    $(document).ready(init);

    return {

    };
})();