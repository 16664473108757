var Tivoli = Tivoli || {};

Tivoli.paaskekonkurrence = (function () {
    "use strict";

    function init() {

        var $easterForm = $(".js-easter-competition-form");
        if ($easterForm.length <= 0) {
            return;
        }

		formValidationHandling($easterForm);
		setupEasterInputMask();
    }

    function setupEasterInputMask() {
		$(".js-easter-mask input").inputmask({
			mask: ["a{7}"], 
			placeholder: '•'
		});
    }

    function formValidationHandling($form) {
        var easterPermissionContainer = $(".js-easter-permission");

        // Ignore required permission until form is submitted without permission - then set as required in order to request the permission from the user
        easterPermissionContainer.find(".js-validation-input").addClass("not-required");

		$form.on("form-success", function(e, data) {
            if (data.Error || !data.Success) {
                return;
            }

			var $confirmTxtHasPerm = $(".js-confirmation-with-perm");
			var $confirmTxtNoPerm = $(".js-confirmation-without-perm");

			if (data.HasPermission) {
				$form.find(".form-container").hide();
				$confirmTxtHasPerm.show();
                $confirmTxtNoPerm.hide();
                dataLayer.push({ 'event': 'newsletter-signup-easter' });
			} else {
			    easterPermissionContainer.find(".js-validation-input").removeClass("not-required");
				$(".js-hide-input-on-confirmation").height(0);
				$confirmTxtNoPerm.show();
				$confirmTxtHasPerm.hide();
			}
		});
		$form.on("form-error", function(e, data) {
			console.log("ERROR", data);
		});
    }

    $(document).ready(function () {
        init();
    });

    return {
        init: init
    };
})();