var Tivoli = Tivoli || {};
Tivoli.VertigoGifCollect = (function () {
    var vertigoGifContainer = $(".js-vertigo-gif-collect-form");


    var smallBreak = Tivoli.settings.width.small;

    function init() {
        if (vertigoGifContainer.length === 0) {
            return;
        }

        $.each($('.js-vertigo-gif-collect-form'), function () {
            getImageByCode($(this));
        });       

        $('.js-vertigo-share').on('click', function() {
            var imgCode = $('.js-vertigo-image img').attr('data-gif-id');
            keepImage(imgCode);

        });
    }

    function keepImage (imgCode) {
         $.ajax({
                url: '/Features/UserMedia/ShareMediaFile?externalCode='+imgCode,
                type: 'GET',
                contentType: 'application/json; charset=utf-8',
                error: function () {
                },
                success: function (data) {
                }
            });
    }

    function loadImage(imgSource, imgCode, videoSource) {
        var img = $("<img />").attr('src', imgSource).attr('data-gif-id', imgCode)
            .on('load', function () {
                if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
                } else {
                    $('.js-vertigo-gif-image-container').show();
                    $(".js-vertigo-image").append(img);
                    $(".js-vertigo-gif-collect-image-container").hide();
                    $('.js-vertigo-gif-download').attr('href', imgSource);
                    $('.js-share-facebook').attr('data-share-link', imgSource);
                    $('.js-share-twitter').attr('data-share-link', imgSource);
                }
            });
    }

    function getImageByCode(formContainer) {

        var outerContainer = formContainer.parents(".js-vertigo-gif-collect-image-container");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        $('.js-vertigo-gif-collect-form').on('submit', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var imageCode = $('#vertigoGifCollectFormImageCode').val();
            var errorCode = $('#vertigoGifCollectFormImageCode').data('error');
            var imageUrl = '/Features/UserMedia/GetMediaUrl?id=' + imageCode;
            Tivoli.CircleLoader.loadInitial(loader, null);
            $.ajax({
                url: imageUrl,
                type: 'GET',
                contentType: 'application/json; charset=utf-8',
                error: function () {
                    Tivoli.CircleLoader.reset(loader);
                    Tivoli.ButtonMessage.removeMessage(button);
                    Tivoli.ButtonMessage.showErrorMessage(button, errorCode);
                },
                success: function (data) {
                    var imgSource = "";
                    var videoSource = "";
                    var externalCode = "";

                    if (data[0].Url && data[1].Url) {
                        externalCode = data[0].ExternalCode;

                        for (var key in data) {
                            if(data[key].ContentType == "image/gif")
                            {
                                imgSource = data[key].Url;
                            }

                            if(data[key].ContentType == "video/mp4")
                            {
                                videoSource = data[key].Url;
                            }
                        }
                        loadImage(data[0].Url, externalCode, data[1].Url);
                    }
                    else {
                        Tivoli.CircleLoader.reset(loader);
                        Tivoli.ButtonMessage.removeMessage(button);
                        Tivoli.ButtonMessage.showErrorMessage(button, errorCode);
                    }

                },

            });
        });
    };

    $(document).ready(function () {
        init();
    });

    return {

    };
})();